import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'

import { config } from './config'

Sentry.init({
  dsn: config.sentryDSN,
  environment: config.environment,
  tracesSampleRate: config.sentryTracesSampleRate,

  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
})
