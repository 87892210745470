import { BusinessOrgs } from '@/enums'
import { daysInFuture, getTomorrow } from '@/utils'
import dayjs from 'dayjs'
import { isValidIBAN } from 'ibantools'
import * as Yup from 'yup'

export const makePaymentsValidationSchema = (messages) =>
  Yup.object().shape({
    iban: Yup.string()
      .required(messages.general)
      .test('is-valid-iban', messages.invalidIban, (value) => {
        if (!value) return false
        return isValidIBAN(value)
      })
      .test(
        'is-german-iban',
        messages.noneGermanIban,
        (value) => value && value.startsWith('DE'),
      ),
    name: Yup.string().required(messages.general),
    sepa: Yup.boolean()
      .oneOf([true], messages.general)
      .required(messages.general),
  })

export const makeCompanyValidationSchema = (messages) =>
  Yup.object().shape({
    address: Yup.object()
      .shape({
        city: Yup.string().required(messages.general),
        postalCode: Yup.number()
          .required(messages.general)
          .typeError(messages.postalCodeTypeError),
        street: Yup.string().required(messages.general),
        streetNr: Yup.string()
          .required(messages.general)
          .typeError(messages.general),
      })
      .typeError(messages.general),
    name: Yup.string()
      .required(messages.general)
      .test('not-only-business-org', messages.nameInvalid, (value) => {
        if (!value) return false
        const businessOrgSuffixes = Object.values(BusinessOrgs)
        return !businessOrgSuffixes.includes(value.trim())
      }),
  })

export const makeStartDateValidationSchema = (messages, categoryId) =>
  Yup.object().shape({
    startDate: Yup.number()
      .test(
        'isValid',
        categoryId === 'LE'
          ? messages.startDateNotValidLe
          : messages.startDateNotValid,
        (val) =>
          getTomorrow().setHours(0, 0, 0, 0) <= val &&
          val <=
            daysInFuture(categoryId === 'LE' ? 90 : 14).setHours(0, 0, 0, 0),
      )
      .required(messages.general)
      .typeError(messages.general),
  })

export const makeOverviewValidationSchema = (messages, categoryId) =>
  Yup.object().shape({
    acceptTerms: Yup.boolean().oneOf([true], messages.general),
    startDate: Yup.number()
      .test(
        'isValid',
        categoryId === 'LE'
          ? messages.startDateNotValidLe
          : messages.startDateNotValid,
        (val) =>
          getTomorrow().setHours(0, 0, 0, 0) <= val &&
          val <=
            daysInFuture(categoryId === 'LE' ? 90 : 14).setHours(0, 0, 0, 0),
      )
      .required(messages.general)
      .typeError(messages.general),
  })

export const makePaymentsAndPoaValidationSchema = (messages) =>
  Yup.object().shape({
    iban: Yup.string().required(messages.general),
    name: Yup.string().required(messages.general),
    sepa: Yup.boolean().oneOf([true], messages.general),
    signature: Yup.string().required(messages.general),
  })

export const makePoaValidationSchema = (messages) =>
  Yup.object().shape({
    birthdate: Yup.number()
      .required(messages.general)
      .test(
        'olderThan18',
        messages.over18,
        (value) => dayjs().diff(dayjs(value), 'years') >= 18,
      )
      .typeError(messages.general),
    signature: Yup.string().required(messages.general),
  })
