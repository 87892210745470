/* eslint-disable import/no-cycle */
import { makeCrudPaths, pack, returns } from '@/utils'
import { append, concat, join, pipe, when } from 'ramda'

const {
  add: addCompany,
  edit: editCompany,
  editWithKey: editCompanyWithKey,
} = makeCrudPaths('company')

const {
  view: viewLocation,
  edit: editLocation,
  add: addLocation,
  addWithKey: addLocationWithKey,
  viewWithKey: viewLocationWithKey,
  editWithKey: editLocationWithKey,
} = makeCrudPaths('location')

const {
  edit: editPayment,
  editWithKey: editPaymentWithKey,
  add: addPayment,
  addWithKey: addPaymentWithKey,
} = makeCrudPaths('payment')

const { add: addUser, addWithKey: addUserWithKey } = makeCrudPaths('user')

const {
  view: viewRecommendation,
  viewWithKey: viewRecommendationWithKey,
} = makeCrudPaths('recommendation')

const addInsurance = '/location/:locationId/insurance/add'
const addInsuranceWithKey = (locationId) =>
  `/location/${locationId}/insurance/add`

const viewInsurance =
  '/location/:locationId/:categoryId/insurance/view/:insuranceId?'

const viewInsuranceWithKey = ({ locationId, categoryId, insuranceId }) =>
  when(
    returns(insuranceId),
    pipe(pack, append(insuranceId), join('/')),
  )(`/location/${locationId}/${categoryId}/insurance/view`)

export const paths = {
  addCompany,
  editCompany,
  editCompanyWithKey,
  poaCompany: `/company/poa/:companyId`,
  authRedirect: '/',
  editLocation,
  editLocationWithKey,
  addLocation: `${addLocation}/:companyId?`,
  addNewLocation: addLocation,
  addLocationWithKey,
  viewLocation,
  viewLocationWithKey,
  addInsurance,
  addInsuranceWithKey,
  viewInsurance,
  viewInsuranceWithKey,
  notVerified: '/not-verified',
  dashboard: '/dashboard',
  customerPortal: '/customer-portal',
  terms: '/terms',
  poa: '/power-of-attorney',
  policy: '/privacy-policy',
  profile: '/profile/:section?/:id?',
  initialInformation: '/initial-information',
  imprint: '/imprint',
  referral: '/referralprogram',
  viewRecommendation,
  viewRecommendationWithKey,
  checkout: `/checkout/:locationId`,
  viewCheckoutWithKey: concat('/checkout/'),
  cart: '/cart/:locationId',
  viewCartWithKey: concat('/cart/'),
  services: `/services`,
  editPayment,
  editPaymentWithKey,
  addPayment: `${addPayment}/:companyId`,
  addPaymentWithKey,
  addUser: `${addUser}/:companyId?`,
  addNewUser: addUser,
  addUserWithKey,
  signup: '/signup',
  assessment: '/location/assessment/:locationId',
  assessmentWithKey: concat('/location/assessment/'),
  whySurein: '/why-surein',
  findInsurances: '/location/find-insurances/:locationId',
  findInsurancesWithKey: concat('/location/find-insurances/'),
  myProfile: '/profile/profile',
  signPoa: '/profile/poa',
  language: '/profile/language',
  payments: '/profile/payments',
  companies: '/profile/companies',
  integrations: '/profile/integrations',
  users: '/profile/users',
  viewOrderConfirmation:
    '/location/:locationId/order-confirmation/:insuranceId',
  viewOrderConfirmationWithKey: ({ locationId, insuranceId }) =>
    `/location/${locationId}/order-confirmation/${insuranceId}`,
  // Start TODO: Funnel type should be dynamic and not these hardcoded versions
  landingPageLiabilitySSF: '/assessment/offer-liability',
  liabilitySSF: '/assessment/offer-liability/start',
  liabilitySSFB: '/assessment/offer-liability-b/start',
  landingPageContentSSF: '/assessment/offer-content',
  contentSSF: '/assessment/offer-content/start',
  landingPageLegalSSF: '/assessment/offer-legal',
  legalSSF: '/assessment/offer-legal/start',
  landingPageLiabilityLead: '/assessment/liability',
  liabilityLead: '/assessment/liability/start',
  landingPageLiabilityLeadB: '/assessment/liability-b',
  liabilityLeadB: '/assessment/liability-b/start',
  landingPageCyberLead: '/assessment/cyber',
  cyberLead: '/assessment/cyber/start',
  landingPageFinancialLossLead: '/assessment/financial-loss',
  financialLossLead: '/assessment/financial-loss/start',
  landingPageProfessionalLiabilityLead: '/assessment/professional-liability',
  professionalLiabilityLead: '/assessment/professional-liability/start',
  landingPageBuildingLead: '/assessment/building',
  buildingLead: '/assessment/building/start',
  landingPageContentLead: '/assessment/content',
  contentLead: '/assessment/content/start',
  landingPageLegalLead: '/assessment/legal',
  legalLead: '/assessment/legal/start',
  // End: Funnel type should be dynamic and not these hardcoded versions
  funnel: '/funnel',
  ssfCheckout: '/ssf/checkout/:categoryId',
  viewSsfCheckoutWithKey: (categoryId) => `/ssf/checkout/${categoryId}`,
  ssfOffer: '/ssf/offer/:categoryId',
  viewSsfOfferWithKey: (categoryId) => `/ssf/offer/${categoryId}`,
  contractsOverview: '/contracts-overview',
  yearlyAssessment: `/yearlyAssessment/:locationId`,
  viewYearlyCheckWithKey: (locationId) => `/yearlyAssessment/${locationId}`,
  integrationsRedirect: '/integrations-redirect',
  integrationsRedirectFailed: '/integrations-redirect-failed',
}
