import { useCallback } from 'react'
import { CardSelect, selectChildrenPropType } from '../../atoms'
import { FormItem } from './Component'
import { formItemPropType } from './helpers'

export const FormCardSelect = ({
  name,
  label,
  initialValue,
  info,
  helperText,
  children,
  ignoreErrorKeys,
  layout,
  ...props
}) => {
  const Component = useCallback(
    ({ onChange, ...field }) => (
      <CardSelect onSelect={onChange} {...field} {...props}>
        {children}
      </CardSelect>
    ),
    [],
  )

  return (
    <FormItem
      helperText={helperText}
      ignoreErrorKeys={ignoreErrorKeys}
      info={info}
      initialValue={initialValue}
      label={label}
      layout={layout}
      name={name}
    >
      <Component />
    </FormItem>
  )
}

FormCardSelect.propTypes = {
  children: selectChildrenPropType,
  ...formItemPropType,
}
