import { ThemeColor } from '@/enums'
import { useFormContext, useFormItem } from '@/providers'
import { isUndefined, noop, oneChild } from '@/utils'
import { cloneElement, useEffect, useState } from 'react'
import { Typography } from '../../atoms'
import { InfoTooltip } from '../InfoTooltip'
import { formItemPropType, getError } from './helpers'
import { Container, HelperMessage, LabelContainer } from './styles'

export const FormItem = ({
  layout = 'vertical',
  name,
  label,
  initialValue = '',
  info,
  helperText,
  children,
  ignoreErrorKeys,
  noMargin,
  darkInfo = false,
  showErrorHelperText = true,
}) => {
  const { setFieldValue, validateField } = useFormContext()
  const { value, onChange, error } = useFormItem(name)
  const [visited, setVisited] = useState(false)
  const errorMsg = getError(error, ignoreErrorKeys)

  const clonedChildren = cloneElement(children, {
    value,
    className: getError(error, ignoreErrorKeys) && 'input-error-styles',
    error: Boolean(errorMsg),
    onChange,
    onFocus: !visited ? () => setVisited(true) : undefined,
  })

  useEffect(() => {
    if (isUndefined(value)) setFieldValue(name, initialValue)
  }, [])

  useEffect(() => {
    if (visited) {
      try {
        validateField(name)
      } catch (e) {
        noop()
      }
    }
  }, [value])

  return (
    <Container layout={layout} noMargin={noMargin}>
      {(label || info) && (
        <LabelContainer layout={layout}>
          <Typography
            color={ThemeColor.b100}
            htmlFor={name}
            variant="inputLabel"
          >
            {label}
          </Typography>
          {info && (
            <InfoTooltip
              content={info}
              dark={darkInfo}
              iconColor={ThemeColor.b50}
              position={layout === 'vertical' ? 'right' : 'left'}
            />
          )}
        </LabelContainer>
      )}
      {clonedChildren}
      {showErrorHelperText && error && (
        <HelperMessage color={ThemeColor.danger2}>{errorMsg}</HelperMessage>
      )}
      {helperText && !error && (
        <HelperMessage color={ThemeColor.b50}>{helperText}</HelperMessage>
      )}
    </Container>
  )
}

FormItem.propTypes = {
  children: oneChild.isRequired,
  ...formItemPropType,
}
