import { renderableType, safeRenderPropComponent } from '@/utils'
import { Input } from '../../styled'
import { Addon, Container } from './styles'

export const InputWithAddon = ({
  addonBefore,
  addonAfter,
  inputComponent: Component = Input,
  ...rest
}) => {
  const AddonBefore = safeRenderPropComponent(addonBefore)
  const AddonAfter = safeRenderPropComponent(addonAfter)

  return (
    <Container hasPrefix={Boolean(addonBefore)} hasSuffix={Boolean(addonAfter)}>
      {addonBefore && (
        <Addon before>
          <AddonBefore />
        </Addon>
      )}
      <Component {...rest} />
      {addonAfter && (
        <Addon before={false}>
          <AddonAfter />
        </Addon>
      )}
    </Container>
  )
}

InputWithAddon.propTypes = {
  addonAfter: renderableType,
  addonBefore: renderableType,
  inputComponent: renderableType,
}
