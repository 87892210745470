import { fromEventTarget } from '@/utils'
import { useCallback } from 'react'
import { Input } from '../../styled'
import { FormItem } from './Component'
import { formItemPropType } from './helpers'

export const FormInput = ({
  name,
  label,
  initialValue,
  info,
  helperText,
  ignoreErrorKeys,
  disabled,
  darkInfo,
  ...props
}) => {
  // TODO: remove useless useCallback.
  // Make sure the change to `disabled` works correctly
  const Component = useCallback(
    ({ onChange, ...field }) => (
      <Input
        onChange={fromEventTarget(onChange)}
        {...field}
        disabled={disabled}
        id={name}
        name={name}
        {...props}
      />
    ),
    [disabled],
  )

  return (
    <FormItem
      darkInfo={darkInfo}
      helperText={helperText}
      ignoreErrorKeys={ignoreErrorKeys}
      info={info}
      initialValue={initialValue}
      label={label}
      name={name}
    >
      <Component />
    </FormItem>
  )
}

FormInput.propTypes = formItemPropType
