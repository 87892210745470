import { api } from './api'

export const getLocationTypes = async (language = 'de') => {
  const data = await api.get(`/public/locations?language=${language}`)

  const locationsLabels = Object.values(data).reduce(
    (acc, val) => ({ ...acc, ...val.locationTypes }),
    {},
  )

  const verticalsLabels = Object.entries(data).reduce(
    (acc, [verticalCode, { label }]) => ({ ...acc, [verticalCode]: label }),
    {},
  )

  const verticalsMap = Object.entries(data).reduce(
    (acc, [verticalCode, { locationTypes }]) => ({
      ...acc,
      [verticalCode]: Object.keys(locationTypes),
    }),
    {},
  )

  return {
    getLocationLabel(code) {
      return locationsLabels[code] ?? null
    },
    getVerticalLabel(code) {
      return verticalsLabels[code] ?? null
    },
    verticalsMap,
    locationsLabels,
  }
}
