import {
  Column,
  FormMonthSelect,
  ImageFromCdn,
  Row,
  Typography,
} from '@/components'
import { AssessmentStepKeys, Breakpoint, ThemeColor } from '@/enums'
import {
  useFormItem,
  useNextDisabled,
  useNextLoading,
  useOnNextClick,
  useSetButtonText,
} from '@/providers'
import { currentYear, isValidDate, useIsMobile, useLanguage } from '@/utils'
import { Alert } from '@surein/ui'
import dayjs from 'dayjs'
import { isNil } from 'ramda'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSaveAssessmentStep } from '../helpers'
import { CompanyFoundedDateDiscountTextContainer } from './styles'

const determineDiscountElegibility = (timestamp) => {
  if (typeof timestamp === 'number') {
    const today = dayjs()
    const timestampDate = dayjs(timestamp)
    const differenceInDays = today.diff(timestampDate, 'day')
    return differenceInDays < 365
  }
  return false
}

export const CompanyFoundedDateLeadStep = () => {
  const { isMobile } = useIsMobile(Breakpoint.sm)
  const { value } = useFormItem('foundedDate')
  const language = useLanguage()
  const [isEligibleForDiscount, setIsEligibleForDiscount] = useState(
    determineDiscountElegibility(value),
  )

  useEffect(() => {
    setIsEligibleForDiscount(determineDiscountElegibility(value))
  }, [value])

  const { save, isLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.companyFoundedDateLead,
  )

  const { t } = useTranslation()

  useOnNextClick(save)

  useNextLoading(() => isLoading, [isLoading])

  const isButtonDisabled = useCallback(
    () => !isValidDate(new Date(value)) || isNil(value),
    [value],
  )

  useNextDisabled(isButtonDisabled, [value])

  useSetButtonText(
    () =>
      isButtonDisabled()
        ? t('stepperButtonTextOptions.foundedDate')
        : t('next'),
    [value],
  )

  return (
    <Column gap="24px">
      <Column gap="24px">
        <FormMonthSelect
          maxDate={new Date(currentYear(), 11, 31, 12)}
          name="foundedDate"
          shouldRenderInModal={isMobile}
          showErrorHelperText={false}
        />
        {isEligibleForDiscount && (
          <Alert text={t('companyFoundedDateDiscount')} variant="success">
            <Row alignItems="center" gap="16px">
              <ImageFromCdn name={`discount-badge-${language}`} type="svg" />
              <CompanyFoundedDateDiscountTextContainer>
                <Typography bold color={ThemeColor.success1} variant="p1Body">
                  {t('startupDiscountApplied')}
                </Typography>
                <Typography color={ThemeColor.success1} variant="p1Body">
                  {t('startupDiscountAppliedDescription')}
                </Typography>
                <ImageFromCdn
                  fallbackType="jpg"
                  name="carrierBubbles"
                  type="webp"
                  width="104px"
                />
              </CompanyFoundedDateDiscountTextContainer>
            </Row>
          </Alert>
        )}
      </Column>
    </Column>
  )
}
