import { Categories } from '@/enums'
import { getLocalStorageItem, setLocalStorageItem } from '@/utils'
import { prop, sortBy } from 'ramda'
import { useTranslation } from 'react-i18next'
import { GetRecommendationByIdApiResponse } from '@/api'

/**
 * @deprecated please use t() directly
 * this util creates lot of coupling and unnecessary indirection
 */
export const useMessages = (categoryId: string) => {
  const { t } = useTranslation()

  const categoryData = t(
    `categories.${Categories[categoryId as keyof typeof Categories]?.key}`,
    {
      returnObjects: true,
    },
  ) as {
    offer: string
    offerDesc: string
  }

  return {
    offerTitle: categoryData.offer,
    offerDescription: categoryData.offerDesc,
    getCoverage: t('getCoverage'),
    bestChoice: t('best_choice'),
    bestCoverage: t('best_coverage'),
    bestPrice: t('best_price'),
    overallRating: t('overall_rating'),
    customerSatisfactionRating: t('costumer_satisfaction'),
    contractCoverageRating: t('scope_of_coverage'),
    claimHandlingRating: t('claims_handling'),
    responseTimeRating: t('processing_speed_enquiries'),
    amountInsured: t('insurance_sum'),
    unlimited: t('unlimited'),
    deductible: t('deductible'),
    address: t('recommendations.details.address'),
    insuranceDetails: t('insurance_details'),
    viewDetails: t('view_details'),
    hideDetails: t('hide_details'),
    errorOnChoose: t('snackbars.error.alreadyInCart'),
    successAdd: t('snackbars.success.checkoutAdd'),
    testimonial: t('testimonial_text'),
    starChef: t('michelin_star_chef'),
    satisfiedEntrepreneurs: t('satisfied_entrepreneurs'),
    customerScore: t('customer_score'),
    cheaperComparison: t('cheaper_comparison'),
    cheaperComparisonDescription: t('cheaper_comparison_description'),
    damageDecisions: t('damage_decisions'),
    damageDecisionsDescription: t('damage_decisions_description'),
    thereForYou: t('there_for_you'),
    thereForYouDescription: t('there_for_you_description'),
    independent: t('independent'),
    independentDescription: t('independent_description'),
    yes: t('common.yes'),
    no: t('common.no'),
    paymentPeriodOptions: t('paymentPeriodOptions', { returnObjects: true }),
    info: t('info', { returnObjects: true }),
    sureinDiscount: t('surein_discount'),
    searchFailed: t('search_failed'),
    offerValidity: t('offer_validity'),
    recommendationPricePeriods: t('recommendationPricePeriods', {
      returnObjects: true,
    }),
    moreQuestions: t('moreQuestions'),
    arrangeCall: t('arrangeCall'),
    contactTitle: t('contactTitle'),
    mainAdvantages: t('mainAdvantages'),
    coverage: t('coverage'),
    moreDetails: t('moreDetails'),
    recommended: t('recommended'),
    paidYearly: t('paidYearly'),
    details: t('details'),
    pricePaidToCarrier: t('pricePaidToCarrier'),
  }
}

const setBestChoiceByDefault = <
  TRecommendation extends {
    bestChoice?: string
    recommendationProducts: {
      recommendationProductId: string
      offerTag?: string | null
    }[]
  }
>(
  recommendation: TRecommendation,
): TRecommendation => {
  const { bestChoice, recommendationProducts } = recommendation
  const hasBestChoice = recommendationProducts.some(
    ({ offerTag }) => offerTag === 'best_choice',
  )

  if (!hasBestChoice && bestChoice) {
    return {
      ...recommendation,
      recommendationProducts: recommendationProducts.map((item) => {
        if (bestChoice === item.recommendationProductId) {
          return { ...item, offerTag: 'best_choice' }
        }
        return item
      }),
    }
  }

  return recommendation
}

// TODO: move to recommendation product module? - probably should be done in the service layer
export const recommendationProductSorter = sortBy(prop('order'))

export const prepareRecommendation = <
  TRecommendation extends {
    bestChoice?: string
    recommendationProducts: {
      recommendationProductId: string
      offerTag?: string | null
      order: number
    }[]
  }
>(
  recommendation: TRecommendation,
) => {
  const a = setBestChoiceByDefault(recommendation)

  return {
    ...recommendation,
    recommendationProducts: recommendationProductSorter(
      a.recommendationProducts,
    ),
  }
}

export const formatRiskAddress = (address: {
  street: string
  // FIXME: why do we have two different fields for street number?
  streetNumber?: string
  streetNr?: string
  postalCode: string
  city: string
}) =>
  `${address.street} ${address.streetNumber || address.streetNr}, ${
    address.postalCode
  } ${address.city}`

// Used to generate some background of the recommendation page
// for the funnel screens, this populates it with the correct
// data and allows to keep it consistent across changes on the
// recommendation component
export const mockResponse: GetRecommendationByIdApiResponse & {
  isSuccessful: boolean
} = {
  categoryId: 'LI',
  locationId: '1',
  userSearchCriteria: null,
  bestChoice: 'c6f66bf5-23ac-42fa-8cfe-54082d900dae',
  visibleAttributes: [],
  recommendationProducts: [
    {
      recommendationProductId: '1',
      carrierId: '1',
      categoryId: 'LI',
      deductible: 0,
      amountInsured: 5000000,
      paymentPeriod: 'yearly',
      grossPrice: 80,
      discountValue: 20,
      amountInsuredUnlimited: false,
      offerTag: null,
      carrierData: {
        logoUrl: 'andsafe.png',
      },
      recommendationProductFiles: [],
      order: 0,
      parametersValues: { de: {}, en: {} },
      yesNoValues: {},
    },
    {
      recommendationProductId: '2',
      carrierId: '2',
      categoryId: 'LI',
      deductible: 0,
      amountInsured: 5000000,
      amountInsuredUnlimited: false,
      paymentPeriod: 'yearly',
      grossPrice: 80,
      discountValue: 20,
      offerTag: null,
      carrierData: {
        logoUrl: 'gothaer.png',
      },
      recommendationProductFiles: [],
      order: 1,
      parametersValues: { de: {}, en: {} },
      yesNoValues: {},
    },
    {
      recommendationProductId: '3',
      carrierId: '3',
      categoryId: 'LI',
      deductible: 0,
      amountInsured: 5000000,
      amountInsuredUnlimited: false,
      paymentPeriod: 'yearly',
      grossPrice: 80,
      discountValue: 20,
      offerTag: null,
      carrierData: {
        logoUrl: 'hdi.png',
      },
      recommendationProductFiles: [],
      order: 2,
      parametersValues: { de: {}, en: {} },
      yesNoValues: {},
    },
  ],
  isSuccessful: true,
}

const makeRecommendationIdLocalStorageKey = (categoryId: string) =>
  `${categoryId}-recommendation-id`

export const getStoredRecommendationId = (categoryId: string) => {
  const key = makeRecommendationIdLocalStorageKey(categoryId)
  return getLocalStorageItem(key)
}

export const storeRecommendationId = (
  categoryId: string,
  recommendationId: string,
) => {
  const key = makeRecommendationIdLocalStorageKey(categoryId)
  setLocalStorageItem(key, recommendationId)
}

// TODO: Urgently remove this and pass over to BE to return all of this
// data in the recommendation response to avoid this kind of logic

// Used to render the amount of touchpoints with the carriers in parenthesis in the reco.
export const carrierRatingAmounts = {
  // Production
  '1f1e2025-46ce-4817-b011-128325d7a95f': 1864, // Gothaer Allgemeine Versicherung AG
  '95206ea9-485b-447d-a86a-3d042fb49778': 878, // andSafe AG
  '42b68255-8e70-470d-8b0d-5f866bf9ee93': 878, // andSafe
  'ca6767ac-0a6d-4ca9-b0b2-4c2ff55a0c9b': 1158, // HDI Versicherung AG
  'a8d78e26-d005-4e10-a99d-0bb52763688c': 668, // ADCURI GmbH
  'ca5d5a39-0ad9-4438-899e-21f530c1f05d': 516, // VHV Allgemeine Versicherung AG

  // Stage
  'bd4e9b1e-2bf9-4cbd-978e-4f05e6c1bbb4': 516, // VHV
  'ebc4bb25-20f4-41cb-9402-25b9cb3e0ef6': 668, // Adcuri
  'acd02ea5-278a-4f63-9659-cb65abbe9d55': 1158, // HDI
  '163f7448-e59f-4eac-86f4-90ce42723c6c': 878, // andSafe
  '73120603-6691-4bf3-8deb-c92191d0f8a7': 1864, // Gothaer
  '01263e67-8bb3-48db-9159-3c52fb71723d': 1864, // Gothaer
}
