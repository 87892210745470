import {
  AdvisorBubble,
  ButtonLink,
  Column,
  FormPhone,
  Header,
  ModalBase,
  TextWithLink,
  Typography,
} from '@/components'
import { ComponentColors, ThemeColor } from '@/enums'
import { LeftArrow, ShieldCheck } from '@/icons'
import { useSegment } from '@/modules/analytics'
import { BasePage } from '@/pages/RecommendationPage/BasePage'
import { mockResponse } from '@/pages/RecommendationPage/helpers'
import { FormProvider } from '@/providers'
import { paths } from '@/routes/paths'
import { tempAuthQueryKeys, useCreateAccountV2 } from '@/services'
import {
  deleteLocalStorageItems,
  getLocalStorageItem,
  noop,
  setLocalStorageItem,
  useApiErrorMessage,
  useGoToPath,
} from '@/utils'
import { Button } from '@surein/ui'
import jwtDecode from 'jwt-decode'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import { makeStoredAssessmentValuesKey } from '../helpers'
import { BackContainer, ModalContent, StyledSSL } from './styles'
import { validationSchema } from './validationSchema'

interface MockRecommendationProps {
  categoryId: string
  onBack: () => void
  onFinish: () => void
  transformSubmitValues?: (values: any) => any
}

export const MockRecommendation = ({
  categoryId,
  onBack,
  onFinish,
  transformSubmitValues,
}: MockRecommendationProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const valuesLocalStorageKey = makeStoredAssessmentValuesKey(categoryId)
  const assessmentValues = getLocalStorageItem(valuesLocalStorageKey)
  const createAccountMutation = useCreateAccountV2(categoryId)
  const { errorMessages } = useApiErrorMessage()
  const goToRoot = useGoToPath(paths.authRedirect)

  const [searchParams] = useSearchParams()
  const analytics = useSegment()

  // Marketing specific params
  const { marketingPid, marketingMid } = useOutletContext<{
    marketingPid: string
    marketingMid: string
  }>()
  const partner = searchParams.get('partner')

  const localStorageKeys = [
    `${categoryId}-values`,
    `${categoryId}-current-step`,
    `${categoryId}-confirmation-id`,
    `${categoryId}-recommendation-id`,
  ]

  const handleSubmit = ({ phone }: { phone: string }) => {
    const [name, ...surname] = assessmentValues.contactData.name.split(' ')
    const formattedSurname = surname.join(' ') || '_'

    const body = {
      assessmentValues,
      name,
      surname: formattedSurname,
      phone,
      email: assessmentValues.contactData.email,
      partner,
      marketingPid,
      marketingMid,
    }

    if (transformSubmitValues) {
      body.assessmentValues = transformSubmitValues(body.assessmentValues)
    }

    createAccountMutation.mutate(body, {
      onSuccess: async (data: { accessToken: string }) => {
        deleteLocalStorageItems(localStorageKeys)
        queryClient.resetQueries(tempAuthQueryKeys.recommendation)
        if (data?.accessToken) {
          const { user_id: userId } = jwtDecode<{ user_id: string }>(
            data?.accessToken,
          )
          setLocalStorageItem('access-token', data?.accessToken)
          analytics.identify(userId, {
            email: body.email,
            first_name: body.name,
            last_name: body.surname,
          })
          await analytics.track('ssf_recommendation_phone_number')
          onFinish()
        }
      },
      // @ts-expect-error fix type on the action - our API client is overwriting response types in interceptors
      onError: async (data: { response?: { data?: { code: string } } }) => {
        if (data.response?.data?.code === 'USER_ALREADY_EXISTS') {
          await analytics.track('ssf_returning_user')
        } else {
          await analytics.track('ssf_general_error')
        }
      },
    })
  }

  return (
    <>
      <Header />
      <BasePage
        data={mockResponse}
        noBack
        onChoose={noop}
        handleFileClick={noop}
      />

      <ModalBase isOpen padding="24px" onClose={noop}>
        <ModalContent>
          <BackContainer onClick={onBack}>
            <Typography color={ThemeColor.b50} singleLine variant="captionR">
              <LeftArrow stroke={ThemeColor.b50} />
              <span>{t('back')}</span>
            </Typography>
          </BackContainer>
          <Typography variant="h4">
            {t('mockRecommendationModalText.title')}
          </Typography>
          <AdvisorBubble text={t('mockRecommendationModalText.advisorText')} />
          <FormProvider
            onSubmit={handleSubmit}
            validationSchema={validationSchema({
              phone: t('validations.phone'),
            })}
          >
            <Column gap="16px">
              <FormPhone
                label={t('phone')}
                name="phone"
                // @ts-expect-error fix type
                noMargin
                placeholder="0123 456 7890"
              />
              <StyledSSL alignItems="center" gap="4px">
                <ShieldCheck color={ThemeColor.success4} />
                <Typography singleLine variant="badgeText">
                  <Trans i18nKey="mockRecommendationModalText.ssl" />
                </Typography>
              </StyledSSL>
              <Button
                loading={createAccountMutation.isLoading}
                shape="square"
                type="submit"
                variant="primary"
              >
                {t('mockRecommendationModalText.cta')}
              </Button>
              {createAccountMutation.isError &&
                // @ts-expect-error coupling to infrastructure concern - TODO: handle error in api/service layer
                createAccountMutation.error?.response?.data?.code && (
                  <Typography
                    color={ThemeColor.danger1}
                    textAlign="center"
                    variant="button"
                  >
                    <TextWithLink color={ThemeColor.danger1} fontSize="16px">
                      <Trans
                        // @ts-expect-error TODO: fix type
                        components={
                          // @ts-expect-error TODO: fix type
                          <ButtonLink
                            color={ComponentColors.danger}
                            onClick={goToRoot}
                            underline
                          />
                        }
                        i18nKey={
                          errorMessages[
                            // @ts-expect-error coupling to infrastructure concern - TODO: handle error in api/service layer
                            createAccountMutation.error.response.data.code
                          ]
                        }
                      />
                    </TextWithLink>
                  </Typography>
                )}
            </Column>
          </FormProvider>
        </ModalContent>
      </ModalBase>
    </>
  )
}
