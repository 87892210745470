import { createMultipleInsurances, getInsuranceById } from '@/api'
import { useInvalidateOnSuccessMutation } from '@/utils'
import { pipe, assoc, map } from 'ramda'
import { useQuery } from 'react-query'
import { useUploadFiles } from './file'

const keys = {
  insurances: 'insurances',
  insurance: 'insurance',
}

export const useInsurance = (
  id: string | undefined,
  options: { enabled?: boolean } = {},
) =>
  useQuery(
    [keys.insurance, id],
    !id ? () => undefined : () => getInsuranceById(id),
    options,
  )

const transform = (locationId: string) =>
  pipe(map(assoc('locationId', locationId)), createMultipleInsurances)

export const useCreateMultipleInsurances = (locationId: string) =>
  useInvalidateOnSuccessMutation(keys.insurances, transform(locationId))

export const useUploadInsuranceFiles = (id: string) =>
  useUploadFiles(id, keys.insurance, 'insurance', 'location')
