import { WithSSFSentryTags } from '@/components'
import {
  AddCompanyPage,
  AddCompanyUserPage,
  AddInsurancesPage,
  AddLocationPage,
  AddPaymentPage,
  BuildingLead,
  CartPage,
  CheckoutPage,
  ConditionalRecommendation,
  ContentConditionalOffer,
  ContractsOverview,
  CyberLead,
  Dashboard,
  EditCompanyPage,
  EditLocationPage,
  EditPaymentPage,
  FindInsurancesPage,
  ImprintPage,
  InitialInformationPage,
  InsuranceViewPage,
  IntegrationsRedirect,
  IntegrationsRedirectFailed,
  LegalConditionalOffer,
  LiabilityConditionalOffer,
  LocationPage,
  NotVerified,
  PoaPage,
  PoaSignPage,
  PolicyPage,
  ProfessionalLiabilityLead,
  Profile,
  PublicCheckoutPage,
  RecommendationPage,
  ReferralPage,
  SelfAssessmentPage,
  Services,
  Terms,
  WhySureinPage,
} from '@/pages'
import { CustomerPortal } from '@/pages/CustomerPortal/Page'
import YearlyAssessment from '@/pages/yearlyAssessment'
import { returns } from '@/utils'
import {
  both,
  complement,
  evolve,
  filter,
  map,
  pipe,
  pluck,
  prop,
  tail,
  when,
} from 'ramda'
import { Navigate } from 'react-router-dom'
import { config } from '../config'
import { AuthRedirect } from './AuthRedirect'
import { paths } from './paths'
import { ProtectedRoute } from './ProtectedRoute'
import { SignupRoute } from './SignupRoute'
import { TempAuthGuard } from './TempAuthGuard'
import { WithFunnelGuard } from './WithFunnelGuard'

const RootEntryPoint = {
  [config.liabilityLeadDomain]: <Navigate to={paths.liabilityLead} />,
  [config.cyberLeadDomain]: <Navigate to={paths.cyberLead} />,
  [config.financialLossLeadDomain]: <Navigate to={paths.financialLossLead} />,
  [config.professionalLiabilityLeadDomain]: (
    <Navigate to={paths.professionalLiabilityLead} />
  ),
  [config.buildingLeadDomain]: <Navigate to={paths.buildingLead} />,
  [config.legalLeadDomain]: <Navigate to={paths.legalLead} />,
  [config.contentLeadDomain]: <Navigate to={paths.contentLead} />,
  [config.liabilitySsfDomain]: <Navigate to={paths.liabilitySSF} />,
  [config.contentSsfDomain]: <Navigate to={paths.contentSSF} />,
  [config.legalSsfDomain]: <Navigate to={paths.legalSSF} />,
}

export const routes = [
  /* PLOP_INJECT_APPEND */
  {
    path: paths.contractsOverview,
    element: <ContractsOverview />,
  },
  {
    path: paths.assessment,
    element: <SelfAssessmentPage />,
  },
  {
    path: paths.services,
    element: <Services />,
  },
  {
    path: paths.referral,
    element: <ReferralPage />,
  },
  {
    path: paths.addUser,
    element: <AddCompanyUserPage />,
  },
  {
    path: paths.editPayment,
    element: <EditPaymentPage />,
  },
  {
    path: paths.addPayment,
    element: <AddPaymentPage />,
  },
  {
    path: paths.profile,
    element: <Profile />,
  },
  {
    path: paths.editCompany,
    element: <EditCompanyPage />,
  },
  {
    path: paths.addCompany,
    element: <AddCompanyPage />,
  },
  {
    path: paths.poaCompany,
    element: <PoaSignPage />,
  },
  {
    path: paths.poa,
    element: <PoaPage />,
    public: true,
  },
  {
    path: paths.imprint,
    element: <ImprintPage />,
    public: true,
  },
  {
    path: paths.initialInformation,
    element: <InitialInformationPage />,
    public: true,
  },
  {
    path: paths.policy,
    element: <PolicyPage />,
    public: true,
  },
  {
    path: paths.terms,
    element: <Terms />,
    public: true,
  },
  { path: paths.notVerified, element: <NotVerified /> },
  {
    path: paths.addLocation,
    element: <AddLocationPage />,
  },
  {
    path: paths.viewInsurance,
    element: <InsuranceViewPage />,
  },
  {
    path: paths.editLocation,
    element: <EditLocationPage />,
  },
  {
    path: paths.viewLocation,
    element: <LocationPage />,
  },
  {
    path: paths.addInsurance,
    element: <AddInsurancesPage />,
  },
  {
    path: paths.dashboard,
    element: <Dashboard />,
  },
  {
    path: paths.customerPortal,
    element: <CustomerPortal />,
  },
  {
    path: paths.cart,
    element: <CartPage />,
  },
  {
    path: paths.checkout,
    element: <CheckoutPage />,
  },
  { path: paths.signup, element: <SignupRoute /> },
  { path: paths.whySurein, element: <WhySureinPage />, public: true },
  {
    path: paths.authRedirect,
    element: RootEntryPoint[window.location.host] || <AuthRedirect />,
    public: [
      config.liabilityLeadDomain,
      config.cyberLeadDomain,
      config.financialLossLeadDomain,
      config.professionalLiabilityLeadDomain,
      config.buildingLeadDomain,
      config.legalLeadDomain,
      config.contentLeadDomain,
      config.liabilitySsfDomain,
      config.contentSsfDomain,
      config.legalSsfDomain,
    ].includes(window.location.host),
  },
  { path: paths.findInsurances, element: <FindInsurancesPage /> },
  {
    path: paths.integrationsRedirect,
    element: <IntegrationsRedirect />,
  },
  {
    path: paths.integrationsRedirectFailed,
    element: <IntegrationsRedirectFailed />,
  },
  {
    path: paths.viewRecommendation,
    element: <RecommendationPage />,
  },
  {
    path: paths.yearlyAssessment,
    element: <YearlyAssessment />,
  },
  {
    path: paths.landingPageLiabilitySSF,
    element: <Navigate to={paths.liabilitySSF} />,
    public: true,
  },
  {
    path: paths.liabilitySSF,
    element: WithSSFSentryTags(<LiabilityConditionalOffer />),
    public: true,
  },
  {
    path: paths.liabilitySSFB,
    element: <Navigate to={paths.liabilitySSF} />,
    public: true,
  },
  {
    path: paths.landingPageContentSSF,
    element: <Navigate to={paths.contentSSF} />,
    public: true,
  },
  {
    path: paths.contentSSF,
    element: WithSSFSentryTags(<ContentConditionalOffer />),
    public: true,
  },
  {
    path: paths.landingPageLegalSSF,
    element: <Navigate to={paths.legalSSF} />,
    public: true,
  },
  {
    path: paths.legalSSF,
    element: WithSSFSentryTags(<LegalConditionalOffer />),
    public: true,
  },
  {
    path: paths.liabilityLead,
    element: <Navigate to={paths.liabilitySSF} />,
    public: true,
  },
  {
    path: paths.liabilityLeadB,
    element: <Navigate to={paths.liabilitySSF} />,
    public: true,
  },
  {
    path: paths.landingPageLiabilityLead,
    element: <Navigate to={paths.liabilitySSF} />,
    public: true,
  },
  {
    path: paths.cyberLead,
    element: <CyberLead />,
    public: true,
  },
  {
    path: paths.landingPageCyberLead,
    element: <Navigate to={paths.cyberLead} />,
    public: true,
  },
  {
    path: paths.financialLossLead,
    element: <Navigate to={paths.liabilitySSF} />,
    public: true,
  },
  {
    path: paths.landingPageFinancialLossLead,
    element: <Navigate to={paths.liabilitySSF} />,
    public: true,
  },
  {
    path: paths.professionalLiabilityLead,
    element: <ProfessionalLiabilityLead />,
    public: true,
  },
  {
    path: paths.landingPageProfessionalLiabilityLead,
    element: <Navigate to={paths.professionalLiabilityLead} />,
    public: true,
  },
  {
    path: paths.buildingLead,
    element: <BuildingLead />,
    public: true,
  },
  {
    path: paths.landingPageBuildingLead,
    element: <Navigate to={paths.buildingLead} />,
    public: true,
  },
  {
    path: paths.contentLead,
    element: <Navigate to={paths.contentSSF} />,
    public: true,
  },
  {
    path: paths.landingPageContentLead,
    element: <Navigate to={paths.contentSSF} />,
    public: true,
  },
  {
    path: paths.landingPageLegalLead,
    element: <Navigate to={paths.legalSSF} />,
    public: true,
  },
  {
    path: paths.legalLead,
    element: <Navigate to={paths.legalSSF} />,
    public: true,
  },
  {
    path: '',
    public: true,
    element: <TempAuthGuard />,
    children: [
      {
        path: tail(paths.ssfCheckout),
        element: WithSSFSentryTags(<PublicCheckoutPage />),
      },
      {
        path: tail(paths.ssfOffer),
        element: WithSSFSentryTags(<ConditionalRecommendation />),
      },
    ],
  },
]

const withoutLeadingSlash = evolve({
  path: tail,
})

const makeProtectedRoutes = when(
  both(
    complement(prop('public')),
    returns(
      ![
        config.liabilityLeadDomain,
        config.cyberLeadDomain,
        config.financialLossLeadDomain,
        config.professionalLiabilityLeadDomain,
        config.buildingLeadDomain,
        config.legalLeadDomain,
        config.contentLeadDomain,
        config.liabilitySsfDomain,
        config.contentSsfDomain,
        config.legalSsfDomain,
      ].includes(window.location.host),
    ),
  ),
  evolve({
    element: (element) => <ProtectedRoute element={element} />,
  }),
)

export const transformRoutes = pipe(
  map(withoutLeadingSlash),
  map(makeProtectedRoutes),
  map(evolve({ element: (element) => <WithFunnelGuard element={element} /> })),
)

const publicRoutes = pipe(filter(prop('public')), pluck('path'))(routes)

export const simpleHeaderRoutes = [
  ...publicRoutes,
  paths.notVerified,
  paths.signup,
]
