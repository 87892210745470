import { ThemeColor } from '@/enums'
import { themeColor } from '@/utils/theme'
import { createGlobalStyle } from 'styled-components'
import Manrope from './Manrope-VariableFont_wght.ttf'

export const createGlobalStyles = () => createGlobalStyle`
  @font-face {
    font-family: Manrope;
    src: url(${Manrope}) format('truetype');
    font-weight: 200;
    font-display: swap;
  }

  @font-face {
    font-family: Manrope;
    src: url(${Manrope}) format('truetype');
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: Manrope;
    src: url(${Manrope}) format('truetype');
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: Manrope;
    src: url(${Manrope}) format('truetype');
    font-weight: 600;
    font-display: swap;
  }


  @font-face {
    font-family: Manrope;
    src: url(${Manrope}) format('truetype');
    font-weight: 700;
    font-display: swap;
  }

  body {
    margin: 0;
    font-family: Manrope;
    font-display: swap;
    font-weight: 400;
    color: ${themeColor(ThemeColor.b100)};

    a {
      text-decoration: none;
    }

    *[disabled] {
      color: ${themeColor(ThemeColor.b50)};
      -webkit-text-fill-color:${themeColor(ThemeColor.b50)};
      cursor: not-allowed !important;
      background: ${themeColor(ThemeColor.b30)};

      svg {
        stroke: ${themeColor(ThemeColor.b50)};
      }
    }
  }
`
