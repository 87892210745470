import { useEffect } from 'react'

export const useClickOutsideEvent = (ref, callback, dependencies = []) =>
  useEffect(() => {
    const handleClickOutside = ({ target }) => {
      if (target && target.classList.contains('modal-mask')) {
        callback()
        return
      }
      if (ref && !ref.contains(target)) {
        callback()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, ...dependencies])
