import { Column, ImageFromCdn, Row } from '@/components'
import { Breakpoint, ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled from 'styled-components'
import { ifProp, prop } from 'styled-tools'

export const LocationCardContent = styled(Row)`
  gap: ${toThemePixels(1)};
  align-items: center;
  justify-content: space-between;
  gap: ${toThemePixels(2)};
  cursor: pointer;
`

export const PageHeader = styled(Column)`
  gap: ${toThemePixels(1)};
  margin-bottom: ${toThemePixels(6)};
`

export const DashboardCompanyCard = styled(Column)`
  gap: ${toThemePixels(2)};
  width: 100%;
  border-radius: ${toThemePixels(4)};
  overflow: hidden;
  padding: ${toThemePixels(4)};
  background: ${ifProp(
    'isHeadquarters',
    'linear-gradient(90deg, #e6e8ff 0%, #f5f6ff 100%)',
    themeColor(ThemeColor.b10),
  )};
`

export const DashboardCompanyCardHeader = styled(Column)`
  gap: ${toThemePixels(2)};
  margin-bottom: ${ifProp('isHeadquarters', toThemePixels(2), 0)};
  > h6 {
    margin-bottom: ${ifProp('isHeadquarters', 0, toThemePixels(2))};
  }
`

export const DashboardCompanyCardItem = styled(Row)`
  padding: ${toThemePixels(4)};
  background-color: ${themeColor(ThemeColor.b0)};
  border: 1px solid ${themeColor(ThemeColor.b30)};
  border-radius: ${toThemePixels(3)};
  gap: ${toThemePixels(6)};

  > * {
    width: 100%;
  }
`
export const StyledHeadquarterLogoContainer = styled(Column)`
  align-items: center;
  justify-content: center;
  height: ${toThemePixels(8)};
  width: ${toThemePixels(8)};
  border-radius: 50%;
  background: white;
`

export const StyledHeadquarterLogo = styled(ImageFromCdn)`
  max-height: 65%;
  max-width: 65%;
`

export const HeadquartersColumn = styled(Column)`
  gap: ${toThemePixels(1)};
  margin-bottom: ${toThemePixels(8)};
`
export const CompaniesColumn = styled(Column)`
  gap: ${toThemePixels(4)};
  > h5 {
    margin-bottom: 0;
  }
`

export const EmptyCompanySearchContainer = styled(Column)`
  align-items: center;
  justify-content: center;
  gap: ${toThemePixels(3)};
  padding: ${toThemePixels(6)} 0;
  text-align: center;

  > button svg {
    margin-left: ${toThemePixels(1)};

    > path {
      stroke: ${themeColor(ThemeColor.electric500)};
    }
  }
`

export const ProSection = styled(Column)`
  gap: ${toThemePixels(6)};
`

export const ProHeader = styled(Row)`
  height: 10vw;
  width: 100%;
  min-height: ${toThemePixels(35)};
  border-radius: ${toThemePixels(2)};
  padding: ${toThemePixels(4)};
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%), url(${prop(
    'logoUrl',
  )});,
    lightgray 50%;
  background-size: cover;
  justify-content: space-between;
  align-items: center;

  > div:first-child {
    align-self: end;
  }
`

export const ProHeaderTextContainer = styled(Column)`
  justify-content: flex-end;
  overflow: hidden;
  margin-bottom: ${ifProp('hasLogo', 0, toThemePixels(6))};

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const ProHeaderLogoWrapper = styled(Row)`
  width: 8vw;
  height: 8vw;
  min-height: ${toThemePixels(25)};
  min-width: ${toThemePixels(25)};
  justify-content: center;
  align-items: center;
  padding: ${toThemePixels(4)};
  background: ${themeColor(ThemeColor.b0)};
  border-radius: 50%;
  aspect-ratio: 1/1;
  margin-right: ${toThemePixels(10)};
  > img {
    width: 80%;
    height: 80%;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    margin-right: 0;
  }
`
