import { ThemeColor } from '@/enums'
import { TrustPilotExtended } from '@/icons/TrustPilotExtended'
import { theme } from '@/theme'
import { StarIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ImageFromCdn, Typography } from '../../../components/atoms'
import { Column, Row } from '../../../components/styled'

const StyledImageFromCdn = styled(ImageFromCdn)`
  height: 32px;
  width: 32px;
  border-radius: 50%;
`

const LightTypography = styled(Typography)`
  font-weight: 400;
`

export const StepperTestimonial = () => {
  const { t } = useTranslation()
  return (
    <>
      <Row gap="4px">
        {[...Array(5)].map((_, i) => (
          <StarIcon
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            color="transparent"
            fill={theme.color[ThemeColor.warning1]}
            size={18}
          />
        ))}
      </Row>
      <Typography bold variant="h4">
        {t('funnelTestimonial.text')}
      </Typography>
      <Row alignItems="center" gap="8px">
        <StyledImageFromCdn name="mi-headshot" type="png" />
        <Column>
          <Typography bold variant="captionR">
            {t('funnelTestimonial.name')}
          </Typography>
          <LightTypography variant="captionR">
            {t('funnelTestimonial.subtitle')}
          </LightTypography>
        </Column>
      </Row>
      <TrustPilotExtended width={160} />
    </>
  )
}
