import { ComponentColors, ThemeColor } from '@/enums'
import {
  CartIcon,
  DashboardIcon,
  FindInsurancesIcon,
  LogoutIcon,
  ReportDamageIcon,
  ServicesIcon,
  SettingsIcon,
} from '@/icons'
import { ContractsIcon } from '@/icons/ContractsIcon'
import { useAccessContext, useAuth } from '@/providers'
import { paths } from '@/routes/paths'
import {
  dynamicArrayPropType,
  dynamicObjectPropType,
  makePathnameWithLanguage,
  noop,
  renderableType,
  useLanguage,
  useTranslationSection,
  useTranslationSectionObject,
} from '@/utils'
import PropTypes from 'prop-types'
import { prop, reject } from 'ramda'
import { NavLink, useLocation } from 'react-router-dom'
import { Badge, TextBadge, Typography } from '../../atoms'
import { Container, SideBarItem, SideBarContent } from './styles'

const useSidebarItems = ({
  cart,
  hasHeadquarters,
  companies,
  userCompanies,
}) => {
  const { locationId = '' } = useAccessContext()
  const language = useLanguage()
  const { pathname, state } = useLocation()
  const navTranslations = useTranslationSection('navigation')
  const menu = useTranslationSectionObject('profile.menu')
  const { logout } = useAuth()

  const currentCompanyId = companies?.find((company) =>
    company.locations.find((location) => location.locationId === locationId),
  )?.companyId

  const permissions = userCompanies?.find(
    (company) => company.companyId === currentCompanyId,
  )?.userType

  const canSeeCart = permissions?.MD || permissions?.owner

  const { from = '' } = state || {}

  const makePath = makePathnameWithLanguage(language)

  return [
    {
      to: makePath(paths.dashboard),
      text: navTranslations('home'),
      active: pathname.includes('/location/view'),
      icon: DashboardIcon,
    },
    {
      to: makePath(paths.findInsurancesWithKey(locationId)),
      text: navTranslations('findInsurances'),
      active: from.includes('/location/find-insurances'),
      hide: !locationId,
      icon: FindInsurancesIcon,
    },
    {
      to: makePath(paths.viewCartWithKey(locationId)),
      text: navTranslations('orders'),
      badge: cart[locationId]?.length,
      hide: !locationId || !canSeeCart,
      icon: CartIcon,
    },
    {
      to: makePath('/contracts-overview'),
      text: navTranslations('contractsOverview'),
      hide: !hasHeadquarters,
      icon: ContractsIcon,
      isProFeature: true,
    },
    {
      text: navTranslations('reportDamage'),
      to: makePath(paths.customerPortal),
      icon: ReportDamageIcon,
    },
    {
      to: makePath('/profile'),
      text: menu.profile,
      active: pathname.includes('/add') || pathname.includes('/edit'),
      icon: SettingsIcon,
    },
    {
      to: makePath('/services'),
      text: navTranslations('services'),
      icon: ServicesIcon,
    },
    {
      text: menu.logOut,
      onClick: () => logout(),
      icon: LogoutIcon,
    },
  ]
}

export const SideBarBase = ({ items, onItemClick }) => (
  <Container>
    <SideBarContent>
      {reject(prop('hide'), items).map(
        ({ to, text, badge, onClick, active, icon: Icon, isProFeature }) => (
          <NavLink key={text} onClick={onClick} to={to}>
            {({ isActive }) => (
              <SideBarItem
                isActive={active || (isActive && to)}
                onClick={onItemClick}
              >
                {Icon && <Icon color={ThemeColor.b50} />}
                <Typography ellipsis variant="p2Body">
                  {text}
                </Typography>
                {Boolean(badge) && (
                  <Badge color={ComponentColors.danger} count={badge} />
                )}
                {isProFeature && <TextBadge text="Pro" />}
              </SideBarItem>
            )}
          </NavLink>
        ),
      )}
    </SideBarContent>
  </Container>
)

SideBarBase.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      badge: PropTypes.number,
      hide: PropTypes.bool,
      icon: renderableType,
      text: PropTypes.string,
      to: PropTypes.string,
    }),
  ),

  onItemClick: PropTypes.func,
}

export const SideBar = ({
  hidden,
  onItemClick = noop,
  cart,
  hasHeadquarters,
  companies,
  userCompanies,
}) => {
  const items = useSidebarItems({
    cart,
    hasHeadquarters,
    userCompanies,
    companies,
  })

  if (hidden) return null

  return <SideBarBase items={items} onItemClick={onItemClick} />
}

SideBar.propTypes = {
  cart: dynamicObjectPropType,
  companies: dynamicArrayPropType,
  hasHeadquarters: PropTypes.bool,
  hidden: PropTypes.bool,
  onItemClick: PropTypes.func,
  userCompanies: dynamicArrayPropType,
}
