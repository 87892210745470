import {
  Column,
  ContactExpert,
  InsuranceFinderCard,
  OtherInsuranceCard,
  PageMainContent,
  PageWidgetContainer,
  SecondaryButton,
  TabPane,
  TabPanel,
  Typography,
} from '@/components'
import { ThemeColor } from '@/enums'
import { paths } from '@/routes/paths'
import { useGoToPath } from '@/utils'
import PropTypes from 'prop-types'
import { keys } from 'ramda'
import { useParams } from 'react-router-dom'
import { StyledPlusIcon } from '../LocationPage/styles'
import {
  getGroupCategories,
  makeCardSectionData,
  makeCompanySubgroups,
  useMessages,
} from './helpers'
import {
  AddinsuranceContainer,
  CompanyInsuranceCategoryContainer,
  InsurancesContainer,
  InsurancesGrid,
  InsurancesHeader,
  PageContainer,
} from './styles'

const TopCardsSection = ({ section }) => {
  const cardsData = makeCardSectionData(section)

  if (!cardsData.length) return null

  return cardsData.map((categoryId) => (
    <InsurancesContainer key={categoryId}>
      <InsuranceFinderCard categoryId={categoryId} primaryCta />
    </InsurancesContainer>
  ))
}

const InsuranceFinderGroupTab = ({ group }) => {
  const messages = useMessages()
  const { locationId } = useParams()

  const handleAddInsurance = useGoToPath(paths.addInsuranceWithKey(locationId))

  return (
    <Column gap="32px">
      <TopCardsSection section={group} />
      <Column gap="24px">
        <InsurancesHeader>
          <AddinsuranceContainer>
            <Typography variant="h4">{messages.recommended}</Typography>
            <SecondaryButton
              icon={StyledPlusIcon}
              onClick={handleAddInsurance}
              size="medium"
            >
              {messages.addInsurance}
            </SecondaryButton>
          </AddinsuranceContainer>
        </InsurancesHeader>
        <InsurancesGrid length={getGroupCategories(group).length}>
          {getGroupCategories(group).map((item) => (
            <OtherInsuranceCard categoryId={item} />
          ))}
        </InsurancesGrid>
      </Column>
    </Column>
  )
}

InsuranceFinderGroupTab.propTypes = {
  group: PropTypes.string,
}

export const FindInsurancesPage = () => {
  const messages = useMessages()
  const companySubgroups = makeCompanySubgroups()

  return (
    <>
      <PageMainContent>
        <PageContainer>
          <Typography variant="h4">{messages.pageTitle}</Typography>
          <TabPanel>
            <TabPane key="important" text={messages.categoryGroups.important}>
              <TopCardsSection section="important" />
            </TabPane>
            <TabPane key="company" text={messages.categoryGroups.company}>
              <TopCardsSection section="company" />
              {keys(companySubgroups).map((groupKey) => (
                <CompanyInsuranceCategoryContainer>
                  <Typography color={ThemeColor.b50} variant="p1Body">
                    {messages.insuranceFinderSubgroups[groupKey]}
                  </Typography>
                  <InsurancesGrid>
                    {companySubgroups[groupKey].map((categoryId) => (
                      <OtherInsuranceCard categoryId={categoryId} />
                    ))}
                  </InsurancesGrid>
                </CompanyInsuranceCategoryContainer>
              ))}
            </TabPane>
            <TabPane
              key="managingDirector"
              text={messages.categoryGroups.managingDirector}
            >
              <InsuranceFinderGroupTab group="managingDirector" />
            </TabPane>
            <TabPane
              key="employeesPension"
              text={messages.categoryGroups.employeesPension}
            >
              <InsuranceFinderGroupTab group="employeesPension" />
            </TabPane>
          </TabPanel>
        </PageContainer>
      </PageMainContent>
      <PageWidgetContainer>
        <ContactExpert />
      </PageWidgetContainer>
    </>
  )
}

TopCardsSection.propTypes = {
  section: PropTypes.string,
}
