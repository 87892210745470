import {
  ButtonLink,
  Column,
  FormInput,
  FormPhone,
  ImageFromCdn,
  ShowWhen,
  TextWithLink,
  Typography,
} from '@/components'
import { Categories, ThemeColor } from '@/enums'
import {
  useFormContext,
  useNextDisabled,
  useNextLoading,
  useOnNextClick,
} from '@/providers'
import { paths } from '@/routes/paths'
import { useCreateLead } from '@/services'
import {
  anyPropMissing,
  clearLocalStorage,
  makeUrlWithLanguage,
  openInNewTab,
  propTypeFromEnumKeys,
  useLanguage,
} from '@/utils'
import PropTypes from 'prop-types'
import { evolve, isEmpty, keys } from 'ramda'
import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { config } from '../../../config'
import { makeBusinessTypeSubmitValue, useAssessmentValues } from '../helpers'
import { StreetRow } from './styles'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    name: t('name'),
    phoneNumber: t('phone'),
    email: t('email'),
    acceptTerms: t('acceptTerms'),
    phoneInfo: t('phoneInfo'),
    createLeadImageTitle: t('createLeadImageTitle'),
    createLeadImageContent: t('createLeadImageContent'),
  }
}

const makeTermsUrl = makeUrlWithLanguage(
  paths.terms,
  `https://${config.appDomain}/`,
)
const makePolicyUrl = makeUrlWithLanguage(
  paths.policy,
  `https://${config.appDomain}/`,
)

const transform = () =>
  evolve({
    businessType: makeBusinessTypeSubmitValue(),
  })

export const CreateLeadStep = ({
  categoryId,
  transformSubmitValues,
  show = {},
  addressMessages = {},
}) => {
  const language = useLanguage()
  const messages = useMessages()
  const { values: assessmentValues } = useAssessmentValues()
  const { customValidate, values } = useFormContext()
  const createLeadMutation = useCreateLead(categoryId)

  const makeBusinessType = transform()

  const save = useCallback(
    () =>
      new Promise((resolve, reject) => {
        customValidate({
          onSuccess: () => {
            const [name, ...surname] = values.name.split(' ')
            const body = {
              ...values,
              name,
              surname: surname.join(' ') || '_',
              assessmentValues: makeBusinessType(
                transformSubmitValues(assessmentValues),
              ),
            }

            createLeadMutation.mutate(body, {
              onSuccess: () => {
                clearLocalStorage()
                resolve()
              },
              onError: (e) => {
                if (
                  e.response &&
                  e.response.status === 400 &&
                  e.response.data?.message ===
                    'User with provided email already exists!'
                ) {
                  clearLocalStorage()
                  resolve()
                } else {
                  reject()
                }
              },
            })
          },
          onFail: reject,
        })
      }),
    [values],
  )

  useOnNextClick(save)

  useNextDisabled(
    () =>
      anyPropMissing(['name', 'phone', 'email'], values) ||
      (!isEmpty(show) && anyPropMissing(keys(show), values.address)),
    [values],
  )

  useNextLoading(() => createLeadMutation.isLoading, [
    createLeadMutation.isLoading,
  ])

  return (
    <>
      <FormInput label={messages.name} name="name" />
      <FormInput label={messages.email} name="email" />
      <FormPhone
        info={messages.phoneInfo}
        label={messages.phoneNumber}
        name="phone"
      />
      <ShowWhen when={show.street || show.streetNr}>
        <StreetRow>
          <ShowWhen when={show.street}>
            <FormInput
              info={addressMessages.streetInfo}
              label={addressMessages.street}
              name="address.street"
            />
          </ShowWhen>
          <ShowWhen when={show.streetNr}>
            <FormInput
              hideArrows
              info={addressMessages.streetNrInfo}
              label={addressMessages.streetNr}
              name="address.streetNr"
              type="number"
            />
          </ShowWhen>
        </StreetRow>
      </ShowWhen>
      <ShowWhen when={show.postalCode || show.city}>
        <StreetRow>
          <ShowWhen when={show.city}>
            <FormInput
              info={addressMessages.cityInfo}
              label={addressMessages.city}
              name="address.city"
            />
          </ShowWhen>
          <ShowWhen when={show.postalCode}>
            <FormInput
              hideArrows
              info={addressMessages.postalCodeInfo}
              label={addressMessages.postalCode}
              name="address.postalCode"
              type="number"
            />
          </ShowWhen>
        </StreetRow>
      </ShowWhen>
      <TextWithLink>
        <Trans
          components={[
            <ButtonLink
              onClick={() => openInNewTab(makePolicyUrl(language))}
              size="small"
            />,
            <ButtonLink
              onClick={() => openInNewTab(makeTermsUrl(language))}
              size="small"
            />,
          ]}
          i18nKey={messages.acceptTerms}
        />
      </TextWithLink>
    </>
  )
}

CreateLeadStep.propTypes = {
  addressMessages: {
    city: PropTypes.string,
    cityInfo: PropTypes.string,
    postalCode: PropTypes.string,
    postalCodeInfo: PropTypes.string,
    street: PropTypes.string,
    streetInfo: PropTypes.string,
    streetNr: PropTypes.string,
    streetNrInfo: PropTypes.string,
  },
  categoryId: propTypeFromEnumKeys(Categories).isRequired,
  show: {
    city: PropTypes.bool,
    postalCode: PropTypes.bool,
    street: PropTypes.bool,
    streetNr: PropTypes.bool,
  },
  transformSubmitValues: PropTypes.func,
}

export const CreateLeadImage = () => {
  const messages = useMessages()

  return (
    <Column alignItems="center" gap="24px" justifyContent="center">
      <ImageFromCdn
        fallbackType="jpg"
        name="discountBadge"
        type="webp"
        width={164}
      />
      <Column alignItems="center" gap="12px" maxWidth="310px">
        <Typography color={ThemeColor.b50} variant="h5">
          {messages.createLeadImageTitle}
        </Typography>
        <Typography color={ThemeColor.b50} textAlign="center" variant="p1Body">
          <Trans
            components={[
              <Typography color={ThemeColor.info1} variant="p1Body" />,
              <Typography
                bold
                color={ThemeColor.info1}
                underline
                variant="captionB"
              />,
            ]}
            i18nKey={messages.createLeadImageContent}
          />
        </Typography>
      </Column>
    </Column>
  )
}
