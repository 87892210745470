import { PrimaryButton, Typography } from '@/components/atoms'
import { Breakpoint, ThemeColor } from '@/enums'
import { ArrowRightLine } from '@/icons'
import {
  calcOriginalYearlyPrice,
  calcYearlyPrice,
} from '@/pages/LocationPage/helpers'
import {
  formatMoney,
  themeBreakpointDown,
  themeColor,
  themeTransition,
  useIsMobile,
} from '@/utils'
import { ChevronDown } from 'lucide-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { ShowWhen } from '../../hoc/ShowWhen'
import { Column, Divider, Row } from '../../styled'
import { CartProduct } from './CartProduct'
import { Container, MobileContainer } from './styles'

const Price = styled(Typography)`
  text-align: end;

  span {
    margin-left: 4px;
    margin-right: 8px;
  }

  span:last-of-type {
    margin-right: 0;
  }
`

const TotalPriceRow = styled(Row)`
  gap: 8px;
  align-items: center;
  justify-content: space-between;

  ${Price}:first-child {
    ${themeBreakpointDown(Breakpoint.lg)} {
      font-weight: 500;
      color: ${themeColor(ThemeColor.b50)};
      font-size: 14px;
    }
  }

  ${themeBreakpointDown(Breakpoint.lg)} {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
`

const OrderDetailsTrigger = styled(Typography)`
  display: flex;
  cursor: pointer;
  height: fit-content;
  align-items: center;
  gap: 2px;

  svg {
    transform: ${ifProp('isOpen', 'rotate(180deg)', 'rotate(0deg)')};
  }
`

const ProductsDropdown = styled(Column)`
  gap: 4px;
  max-height: ${ifProp('isOpen', '50vh', '0')};
  transition: ${themeTransition()};
  overflow: scroll;
  padding-bottom: 4px;

  ::-webkit-scrollbar {
    display: none;
  }

  ${Divider} {
    margin: 20px 0;
  }
`

const useMessages = () => {
  const { t } = useTranslation()

  return {
    price: t('recommendations.details.grossPrice'),
    total: t('checkout.total'),
    seeDetails: t('checkout.seeDetails'),
    hideDetails: t('checkout.hideDetails'),
    includesVat: t('checkout.includesVat'),
    subtotal: t('checkout_subtotal'),
    discount: t('surein_discount'),
    yearly: t('recommendationPricePeriods.yearly'),
    sepa: t('sepa'),
  }
}

export const CheckoutPriceCardBase = ({
  data,
  buttonText,
  onButtonClick,
  hideProducts,
}) => {
  const messages = useMessages()
  const { isMobile } = useIsMobile(Breakpoint.sm)

  const totalPrice = useMemo(() => calcYearlyPrice(data), [data])
  const originalPrice = useMemo(() => calcOriginalYearlyPrice(data), [data])

  const discount = useMemo(() => originalPrice - totalPrice, [
    originalPrice,
    totalPrice,
  ])

  return (
    <Container>
      <ShowWhen when={!isMobile && !hideProducts}>
        {data.map((productData) => (
          <CartProduct {...productData} key={data.insuranceProductId} />
        ))}
      </ShowWhen>
      <Column gap="8px">
        <Row gap="8px" justifyContent="space-between">
          <Typography color={ThemeColor.b50} variant="p2Body">
            {messages.subtotal}
          </Typography>
          <Price bold color variant="badgeText">
            {formatMoney(originalPrice)}
            <Typography color={ThemeColor.b50} variant="badgeText">
              {messages.yearly}
            </Typography>
          </Price>
        </Row>
        {!!discount && (
          <Row gap="8px" justifyContent="space-between">
            <Typography color={ThemeColor.b50} variant="p2Body">
              {messages.discount}
            </Typography>
            <Price bold color={ThemeColor.success2} variant="badgeText">
              -{formatMoney(discount)}
            </Price>
          </Row>
        )}
        <Divider color={ThemeColor.b30} />
        <TotalPriceRow>
          <Price bold variant="p1Body">
            {messages.total}
            <Typography color={ThemeColor.b50} variant="p2Body">
              {messages.includesVat}
            </Typography>
          </Price>
          <Price bold color variant="h5">
            {formatMoney(totalPrice)}
            <Typography color={ThemeColor.b50} variant="badgeText">
              {messages.yearly.toLowerCase()}
            </Typography>
          </Price>
        </TotalPriceRow>
      </Column>
      {onButtonClick && (
        <PrimaryButton
          icon={() => <ArrowRightLine size={18} />}
          onClick={onButtonClick}
          reverted
        >
          {buttonText}
        </PrimaryButton>
      )}
    </Container>
  )
}

CheckoutPriceCardBase.propTypes = {
  buttonText: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      grossPrice: PropTypes.number,
      originalGrossPrice: PropTypes.number,
      paymentPeriod: PropTypes.string,
    }),
  ),
  description: PropTypes.string,
  hideProducts: PropTypes.bool,
  onButtonClick: PropTypes.func,
}

export const MobileCheckoutPriceCardBase = ({
  data,
  buttonText,
  onButtonClick,
  isCheckoutDetailsOpen,
  toggle,
}) => {
  const messages = useMessages()

  const totalPrice = useMemo(() => calcYearlyPrice(data), [data])

  const originalPrice = useMemo(() => calcOriginalYearlyPrice(data), [data])

  const discount = useMemo(() => originalPrice - totalPrice, [
    originalPrice,
    totalPrice,
  ])

  return (
    <MobileContainer>
      <Column gap="4px">
        <Row gap="8px" justifyContent="space-between">
          <OrderDetailsTrigger
            color={ThemeColor.info1}
            isOpen={isCheckoutDetailsOpen}
            onClick={toggle}
            variant="badgeText"
          >
            {isCheckoutDetailsOpen ? messages.hideDetails : messages.seeDetails}
            <ChevronDown size={12} />
          </OrderDetailsTrigger>
          <Column gap="4px">
            <Price color variant="p1Body">
              <ShowWhen when={discount}>
                <Typography
                  bold
                  color={ThemeColor.success2}
                  variant="badgeText"
                >
                  -{formatMoney(discount)}
                </Typography>
              </ShowWhen>

              {formatMoney(totalPrice)}
              <Typography color={ThemeColor.b50} variant="badgeText">
                {messages.yearly}
              </Typography>
            </Price>
            <Row justifyContent="flex-end">
              <Typography color={ThemeColor.b50} variant="badgeText">
                {messages.total} {messages.includesVat}
              </Typography>
            </Row>
          </Column>
        </Row>
      </Column>
      <ProductsDropdown isOpen={isCheckoutDetailsOpen}>
        <Divider color={ThemeColor.b30} />
        {data.map((productData) => (
          <CartProduct {...productData} key={data.insuranceProductId} />
        ))}
      </ProductsDropdown>
      {onButtonClick && (
        <PrimaryButton
          icon={() => <ArrowRightLine size={18} />}
          onClick={onButtonClick}
          reverted
        >
          {buttonText}
        </PrimaryButton>
      )}
    </MobileContainer>
  )
}

MobileCheckoutPriceCardBase.propTypes = {
  buttonText: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      grossPrice: PropTypes.number,
      originalGrossPrice: PropTypes.number,
      paymentPeriod: PropTypes.string,
    }),
  ),
  hideProducts: PropTypes.bool,
  isCheckoutDetailsOpen: PropTypes.bool,
  onButtonClick: PropTypes.func,
  toggle: PropTypes.func,
}
