import {
  dynamicArrayPropType,
  dynamicObjectPropType,
  isObject,
  isString,
} from '@/utils'
import PropTypes from 'prop-types'
import {
  complement,
  fromPairs,
  head,
  includes,
  isNil,
  last,
  length,
  lt,
  pipe,
  prop,
  reject,
  toPairs,
  when,
  __,
} from 'ramda'

import de from 'react-phone-number-input/locale/de'
import en from 'react-phone-number-input/locale/en'

const ignoreErrors = (ignore) =>
  pipe(
    toPairs,
    reject(pipe(head, includes(__, ignore))),
    head,
    when(complement(isNil), last),
  )

export const getError = (error, ignore = []) => {
  if (isString(error)) return error

  const makeError = ignoreErrors(ignore)

  if (isObject(error)) return makeError(error)

  return null
}

export const makeCountries = pipe(
  prop(__, { en, de }),
  toPairs,
  reject(pipe(head, length, lt(2))),
  fromPairs,
)

export const formItemValuePropType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
  PropTypes.bool,
  dynamicObjectPropType,
  dynamicArrayPropType,
])

export const formItemPropType = {
  initialValue: formItemValuePropType,
  label: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, dynamicArrayPropType])
    .isRequired,
}
