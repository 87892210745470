import { Breakpoint, ThemeColor } from '@/enums'
import {
  ifNotProps,
  themeBreakpointDown,
  themeColor,
  themeTransition,
  toThemePixels,
} from '@/utils'
import styled, { css, keyframes } from 'styled-components'
import { ifProp, switchProp } from 'styled-tools'

export const Container = styled.div``

const gridStyles = css`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 10px));
  ${themeBreakpointDown(Breakpoint.sm)} {
    grid-template-columns: repeat(1, 1fr);
  }
`

const verticalStyles = css`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const CardSelectContainer = styled.div`
  gap: ${toThemePixels(5)};
  ${switchProp('alignment', {
    vertical: verticalStyles,
    grid: gridStyles,
  })}
`

const showIcon = keyframes`
from {
  opacity: 0;
}
`

export const StyledOption = styled.div`
  position: relative;
  width: ${ifProp('alignment', {
    vertical: '100%',
  })};
  border-radius: ${toThemePixels(3)};
  cursor: pointer;
  border: 1px solid
    ${ifProp(
      'error',
      themeColor(ThemeColor.danger2),
      ifProp(
        'selected',
        themeColor(ThemeColor.success2),
        themeColor(ThemeColor.b30),
      ),
    )};
  display: flex;
  justify-content: ${ifProp('centered', 'center', 'space-between')};
  align-items: baseline;
  padding: ${toThemePixels(4)};
  transition: ${themeTransition('background-color')};
  background-color: ${ifProp(
    'selected',
    themeColor(ThemeColor.success4),
    ifProp('error', themeColor(ThemeColor.danger4)),
  )};
  color: ${ifProp(
    'selected',
    themeColor(ThemeColor.b100),
    themeColor(ThemeColor.b50),
  )};

  &:hover {
    border-color: ${ifNotProps(
      ['selected', 'disabled'],
      themeColor(ThemeColor.success2),
    )};
  }

  svg {
    fill: none;
    animation: ${showIcon} 0.4s;
    position: absolute;
    right: 16px;
  }
`
