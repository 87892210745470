import {
  CarrierLogo,
  Column,
  FormCheckBox,
  FormDatepicker,
  Loader,
  Row,
  Typography,
} from '@/components'
import { ThemeColor } from '@/enums'
import { WhatIsProtected } from '@/modules/checkout/components/WhatIsProtected'
import { FormProvider, useFormContext } from '@/providers'
import {
  usePublicCheckoutOverview,
  useUpdatePublicCheckoutOverview,
} from '@/services'
import { useLocationTypes } from '@/services/locationTypes'
import {
  daysInFuture,
  dynamicObjectPropType,
  formatMoney,
  pluralize,
  themeColor,
  toThemePixels,
  useLocalStorage,
} from '@/utils'
import { Badge, Card, Skeleton } from '@surein/ui'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { makeOverviewValidationSchema } from './validationSchema'

const OverviewCard = styled(Card)`
  padding: 16px;
  background-color: ${themeColor(ThemeColor.b10)};
`

const ProductOverview = styled(Row)`
  gap: 8px;
  align-items: center;

  img {
    background-color: ${themeColor(ThemeColor.b0)};
    border-radius: ${toThemePixels(3)};
    border: 1px solid ${themeColor(ThemeColor.b30)};
    height: ${toThemePixels(12)};
    width: ${toThemePixels(12)};
  }

  h5 {
    white-space: wrap;
    word-break: break-word;
  }
`

const InfoCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 4px;
  flex: 1;
`

const makeLocationSubtypes = (locationLabelGetter, locationSubtypes) =>
  locationSubtypes.map((subtype) => locationLabelGetter(subtype)).join(', ')

const useMessages = () => {
  const { t } = useTranslation()
  return {
    validations: t('validations', { returnObjects: true }),
    paymentPeriod: t('payment_period'),
    paymentPeriodOptions: t('paymentPeriodOptions', { returnObjects: true }),
    sepaDebit: t('sepa_debit'),
    change: t('change'),
    checkoutOverviewCheckbox: t('checkout_overview_checkbox'),
    startDateNotValid: t('start_date_not_valid'),
    startDateNotValidLe: t('start_date_not_valid_le'),
    startDate: t('starting_date'),
    startDateDescription: t('start_date_description'),
    topPerformance: t('checkout.topPerformance'),
    whatIsProtected: t('whatIsProtected'),
    insuranceSum: t('insurance_sum'),
    deductible: t('deductible'),
    coverage: t('coverage'),
    cancellation: t('cancellation'),
    cancelTwoWeeksAfterSigning: t('cancelTwoWeeksAfterSigning'),
    acceptInsuranceTerms: t('acceptInsuranceTerms'),
    unlimited: t('unlimited'),
    areDeemedToBeCoinsured: t('areDeemedToBeCoinsured'),
    isDeemedToBeCoinsured: t('isDeemedToBeCoinsured'),
  }
}

const OverviewStepForm = ({ setButtonState, checked, data }) => {
  const { categoryId } = useParams()
  const messages = useMessages()
  const { submitForm, customValidate } = useFormContext()
  const {
    data: locationTypesData,
    isLoading: locationTypesLoading,
  } = useLocationTypes()

  useEffect(() => {
    setButtonState({
      onClick: () =>
        customValidate({
          onSuccess: submitForm,
        }),
    })
  }, [])

  return (
    <Column gap="24px">
      <OverviewCard variant="borderless">
        <Column gap="28px">
          <ProductOverview gap="8px">
            <CarrierLogo name={data.carrierData?.logoUrl} />
            <Typography ellipsis variant="h5">
              {data.carrierData?.name}
            </Typography>
            <Badge isRounded variant="info">
              {messages.topPerformance}
            </Badge>
          </ProductOverview>
          <WhatIsProtected categoryId={categoryId} />
          <Row gap="16px" wrap="wrap">
            <InfoCard variant="borderless">
              <Typography bold color={ThemeColor.info1} variant="badgeText">
                {messages.deductible}
              </Typography>
              <Typography bold variant="p1Body">
                {formatMoney(data.deductible)}
              </Typography>
            </InfoCard>
            <InfoCard variant="borderless">
              <Typography bold color={ThemeColor.info1} variant="badgeText">
                {messages.insuranceSum}
              </Typography>
              <Typography bold variant="p1Body">
                {data.amountInsuredUnlimited
                  ? messages.unlimited
                  : formatMoney(data.amountInsured)}
              </Typography>
            </InfoCard>
          </Row>
          <Column gap="16px">
            <Column>
              <Typography bold color={ThemeColor.b50} variant="badgeText">
                {messages.startDate}
              </Typography>
              <Typography variant="p2Body">
                <FormDatepicker
                  initialValue={data.startDate}
                  max={
                    categoryId === 'LE' ? daysInFuture(90) : daysInFuture(14)
                  }
                  min={daysInFuture(1)}
                  name="startDate"
                  validateOnChange
                />
              </Typography>
            </Column>
            <Column>
              <Typography bold color={ThemeColor.b50} variant="badgeText">
                {messages.paymentPeriod}
              </Typography>
              <Typography variant="p2Body">
                {messages.paymentPeriodOptions[data.paymentPeriod]}
              </Typography>
            </Column>
            <Column>
              <Typography bold color={ThemeColor.b50} variant="badgeText">
                {messages.coverage}
              </Typography>
              {locationTypesLoading ? (
                <Skeleton width="240px" />
              ) : (
                <Typography variant="p2Body">
                  {locationTypesData?.getLocationLabel(data.locationType)}
                  {data.locationSubtypes.length
                    ? ` - ${makeLocationSubtypes(
                        locationTypesData?.getLocationLabel,
                        data.locationSubtypes,
                      )} ${pluralize(
                        data.locationSubtypes.length,
                        messages.isDeemedToBeCoinsured,
                        messages.areDeemedToBeCoinsured,
                        false,
                      )}`
                    : ''}
                </Typography>
              )}
            </Column>
            <Column>
              <Typography bold color={ThemeColor.b50} variant="badgeText">
                {messages.cancellation}
              </Typography>
              <Typography variant="p2Body">
                {messages.cancelTwoWeeksAfterSigning}
              </Typography>
            </Column>
          </Column>
        </Column>
      </OverviewCard>
      <FormCheckBox
        initialValue={checked}
        label={messages.checkoutOverviewCheckbox}
        name="acceptTerms"
        showErrorHelperText={false}
      />
    </Column>
  )
}

OverviewStepForm.propTypes = {
  checked: PropTypes.bool,
  data: dynamicObjectPropType,
  setButtonState: PropTypes.func,
}

export const PublicCheckoutOverviewStep = ({ onNext, setButtonState }) => {
  const messages = useMessages()
  const { categoryId } = useParams()
  const { data, isLoading } = usePublicCheckoutOverview()
  const [checked] = useLocalStorage(
    `${categoryId}-public-checkout-overview-checkbox`,
  )
  const updateCheckoutOverviewMutation = useUpdatePublicCheckoutOverview(
    data?.recommendationProductId,
  )

  useEffect(() => {
    setButtonState({
      loading: updateCheckoutOverviewMutation.isLoading,
    })
  }, [updateCheckoutOverviewMutation.isLoading])

  const handleSubmit = (formValues) => {
    updateCheckoutOverviewMutation.mutate(formValues, { onSuccess: onNext })
  }

  if (isLoading) return <Loader />

  return (
    <FormProvider
      initialValues={{ startDate: data?.startDate }}
      onSubmit={handleSubmit}
      validationSchema={makeOverviewValidationSchema(
        messages.validations,
        categoryId,
      )}
    >
      <OverviewStepForm
        checked={checked}
        data={data}
        setButtonState={setButtonState}
      />
    </FormProvider>
  )
}

PublicCheckoutOverviewStep.propTypes = {
  onNext: PropTypes.func,
  setButtonState: PropTypes.func,
}
