import { useAnimateEffect, componentColorPropType } from '@/utils'
import PropTypes from 'prop-types'
import { Typography } from '../Typography'
import { Container } from './styles'

export const Snackbar = ({ color, children, duration = 5000, onClose }) => {
  const { isOpen, leaving } = useAnimateEffect({
    onLeave: onClose,
    duration,
    defaultOpened: Boolean(children),
  })

  if (!isOpen) return null

  return (
    <Container color={color} leaving={leaving}>
      <Typography variant="p1Body">{children}</Typography>
    </Container>
  )
}

Snackbar.propTypes = {
  children: PropTypes.string,
  color: componentColorPropType,
  duration: PropTypes.number,
  onClose: PropTypes.func,
}
