import './index.css'
import '@surein/ui/styles/index.css'
import './sentry'

import { TooltipProvider } from '@surein/ui'
import { StrictMode, Suspense } from 'react'
import { CookiesProvider } from 'react-cookie'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { Loader } from './components'
import './i18n/init'
import {
  AuthProvider,
  ReactQueryProvider,
  SnackbarProvider,
  ThemeProvider,
} from './providers'
import { ContactModalProvider } from './providers/ContactModalProvider'
import { router } from './routes'

ReactDOM.createRoot(document.getElementById('root')).render(
  <StrictMode>
    <ThemeProvider>
      <Suspense fallback={<Loader />}>
        <ContactModalProvider>
          <CookiesProvider>
            <SnackbarProvider>
              <TooltipProvider delayDuration={150}>
                <AuthProvider>
                  <ReactQueryProvider>
                    <RouterProvider router={router} />
                  </ReactQueryProvider>
                </AuthProvider>
              </TooltipProvider>
            </SnackbarProvider>
          </CookiesProvider>
        </ContactModalProvider>
      </Suspense>
    </ThemeProvider>
  </StrictMode>,
)
