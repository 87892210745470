import PropTypes from 'prop-types'
import { not, pipe } from 'ramda'
import { useCallback } from 'react'
import { CheckBox } from '../../atoms'
import { FormItem } from './Component'
import { formItemValuePropType } from './helpers'

export const FormCheckBox = ({
  name,
  initialValue = false,
  ignoreErrorKeys,
  ...props
}) => {
  const Component = useCallback(
    ({ onChange, value, ...field }) => (
      <CheckBox
        checked={Boolean(value)}
        onChange={pipe(Boolean, not, onChange)}
        {...field}
        id={name}
        name={name}
        {...props}
      />
    ),
    [],
  )

  return (
    <FormItem
      ignoreErrorKeys={ignoreErrorKeys}
      initialValue={initialValue}
      name={name}
      showErrorHelperText={false}
    >
      <Component />
    </FormItem>
  )
}

FormCheckBox.propTypes = {
  ignoreErrorKeys: PropTypes.arrayOf(PropTypes.string),
  initialValue: formItemValuePropType,
  name: PropTypes.string.isRequired,
  showErrorHelperText: PropTypes.bool,
}
