import { ThemeColor } from '@/enums'
import PropTypes from 'prop-types'
import { Image } from '../Image'
import { Typography } from '../Typography'
import { Container, TextBlock } from './styles'

export const AdvisorBubble = ({ text }) => (
  <Container>
    <Image name="chatblock" type="png" />
    <TextBlock>
      <Typography color={ThemeColor.b50} variant="p1Body">
        {text}
      </Typography>
    </TextBlock>
  </Container>
)

AdvisorBubble.propTypes = {
  text: PropTypes.string.isRequired,
}
