import {
  GoogleIcon,
  SslIcon,
  SslTextIcon,
  SureinTrustLogo,
  TrustPilotIcon,
  TrustPilotStarIcon,
} from '@/icons'
import { themeColorFrom, toThemePixels } from '@/utils'
import { prop } from 'ramda'
import styled from 'styled-components'
import { Row } from '../../styled'

export const Container = styled(Row)`
  gap: ${toThemePixels(4.5)};
  align-items: center;
  justify-content: center;
  width: fit-content;

  @media (max-width: 400px) {
    gap: 0;
    justify-content: space-between;
    width: 100%;
  }
`

export const Google = styled(Row)`
  gap: ${toThemePixels(2)};
  align-items: center;
`

export const GoogleRating = styled(Row)`
  align-items: center;
`

export const Rating = styled.span`
  color: ${themeColorFrom(prop('color'))};
  font-size: ${toThemePixels(3)};
  padding-left: ${toThemePixels(1)};
`

export const StyledTrustPilotIcon = styled(TrustPilotIcon)`
  width: ${toThemePixels(14)};
`

export const StyledTrustPilotStarIcon = styled(TrustPilotStarIcon)`
  width: ${toThemePixels(14)};
`

export const Ssl = styled(Row)`
  gap: ${toThemePixels(2)};
`

export const StyledSslIcon = styled(SslIcon)`
  width: ${toThemePixels(5)};
  height: ${toThemePixels(6.5)};
  fill: none;
`

export const StyledSslTextIcon = styled(SslTextIcon)`
  width: ${toThemePixels(20)};
  height: ${toThemePixels(6.5)};
`

export const StyledSureinTrustLogo = styled(SureinTrustLogo)`
  width: ${toThemePixels(7)};
  height: ${toThemePixels(8)};
`

export const StyledGoogleIcon = styled(GoogleIcon)`
  width: ${toThemePixels(5.5)};
  height: ${toThemePixels(5.5)};
`
