import { Column, PageLayout, Row } from '@/components'
import { ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled, { keyframes } from 'styled-components'

export const SuccessPageContainer = styled(PageLayout)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${toThemePixels(12)};
  background: ${themeColor(ThemeColor.b0)};
`

export const SuccessPageHeader = styled.div`
  max-width: 1080px;
  width: 100%;
  gap: ${toThemePixels(4)};
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: ${toThemePixels(8)};
  margin-bottom: ${toThemePixels(5)};

  button {
    align-self: flex-start;
  }

  @media (max-width: 820px) {
    display: flex;
    flex-direction: column;
  }
`

export const ImageContainer = styled.div`
  max-height: 396px;
  justify-self: center;

  img {
    width: auto;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 820px) {
    img {
      width: 100%;
    }
  }
`

export const ModalContent = styled(Column)`
  gap: ${toThemePixels(6)};

  button {
    margin-top: ${toThemePixels(2)};
  }
`

export const ThankYouPageContainer = styled(Column)`
  padding-top: ${toThemePixels(32)};
  background: ${themeColor(ThemeColor.b10)};
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  gap: ${toThemePixels(23)};
  padding-bottom: ${toThemePixels(30)};
`

export const ThankYouTextContainer = styled(Column)`
  max-width: 510px;
  align-items: center;
  text-align: center;
  gap: ${toThemePixels(3)};
  padding: ${toThemePixels(2)};
`

export const IconBackground = styled(Column)`
  background: ${themeColor(ThemeColor.success3)};
  border-radius: 50%;
  justify-content: center;
  justify-content: center;
  align-items: center;
  height: 64px;
  width: 64px;

  > svg {
    color: ${themeColor(ThemeColor.success1)};
  }
`

const showAnimation = keyframes`
 0% { max-height: 0; display: none;}
 50% { max-height: 0; display: none;}
 100% { max-height: 2500px;}
`

export const ThankYouPageAnimationContainer = styled.div`
  overflow: hidden;
  animation-name: ${showAnimation};
  animation-duration: 6s;
  animation-fill-mode: forwards;

  ${themeBreakpointDown('sm')} {
    width: 100%;
  }
`

export const StyledSSL = styled(Row)`
  width: fit-content;
  padding: ${toThemePixels(2)};
  background: ${themeColor(ThemeColor.success4)};
  border: 1px solid ${themeColor(ThemeColor.success3)};
  border-radius: ${toThemePixels(2)};
`

export const BackContainer = styled(Row)`
  align-self: baseline;
  cursor: pointer;
  > span {
    display: flex;
    align-items: center;
    gap: ${toThemePixels(0.5)};
  }

  svg {
    stroke: ${themeColor(ThemeColor.b50)}!important;
    fill: ${themeColor(ThemeColor.b50)}!important;
  }
`

export const CheckIconBackground = styled(Column)`
  background: ${themeColor(ThemeColor.success3)};
  border-radius: 50%;
  justify-content: center;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;

  > svg {
    color: ${themeColor(ThemeColor.success1)};
  }
`
