import { Breakpoint, ThemeColor } from '@/enums'
import { useSegment } from '@/modules'
import {
  useFormContext,
  useNextDisabled,
  useOnNextClick,
  useSetButtonText,
} from '@/providers'
import { paths } from '@/routes/paths'
import {
  anyPropMissing,
  makeUrlWithLanguage,
  openInNewTab,
  useIsMobile,
  useLanguage,
  useTranslationSectionObject,
} from '@/utils'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ButtonLink, ImageFromCdn, Typography } from '../../../components/atoms'
import { FormInput } from '../../../components/molecules'
import { TextWithLink } from '../../../components/styled'
import { config } from '../../../config'
import {
  ButtonLinkFontWrapper,
  ContactDataImageWrapper,
  OfferReadyImageContainer,
} from '../styles'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    name: t('name'),
    phoneNumber: t('phone'),
    email: t('email'),
    acceptTerms: t('acceptTerms'),
    phoneInfo: t('phoneInfo'),
    createLeadImageTitle: t('createLeadImageTitle'),
    createLeadImageContent: t('createLeadImageContent'),
    contactDataImageText: useTranslationSectionObject('contactDataImageText'),
  }
}

const makeTermsUrl = makeUrlWithLanguage(
  paths.terms,
  `https://${config.appDomain}/`,
)
const makePolicyUrl = makeUrlWithLanguage(
  paths.policy,
  `https://${config.appDomain}/`,
)

export const ButtonLinkWrapper = ({ children, onClick }) => (
  <ButtonLinkFontWrapper>
    <ButtonLink onClick={onClick} size="small">
      <Typography variant="badgeText">{children}</Typography>
    </ButtonLink>
  </ButtonLinkFontWrapper>
)

ButtonLinkWrapper.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
}

export const ContactDataStep = ({ handleCalculationSuccessful }) => {
  const { t } = useTranslation()
  const language = useLanguage()
  const messages = useMessages()
  const { values, customValidate } = useFormContext()
  const analytics = useSegment()

  const handleNext = useCallback(
    () =>
      new Promise((resolve, reject) => {
        customValidate({
          onSuccess: async () => {
            await analytics.track('ssf_lead_gen_email_success')
            handleCalculationSuccessful?.()
          },
          onFail: reject,
        })
      }),
    [values],
  )

  const isButtonDisabled = useCallback(
    () => anyPropMissing(['name', 'email'], values),
    [values],
  )

  useNextDisabled(() => isButtonDisabled(), [values])

  useSetButtonText(
    () =>
      isButtonDisabled()
        ? t('stepperButtonTextOptions.contactData')
        : t('next'),
    [values],
  )

  useOnNextClick(handleNext)

  return (
    <>
      <FormInput label={messages.name} name="name" placeholder="John Müller" />
      <FormInput
        label={messages.email}
        name="email"
        placeholder="example@mail.com"
      />
      <TextWithLink>
        <Trans
          components={[
            <ButtonLinkWrapper
              onClick={() => openInNewTab(makePolicyUrl(language))}
              size="small"
            />,
            <ButtonLinkWrapper
              onClick={() => openInNewTab(makeTermsUrl(language))}
              size="small"
            />,
          ]}
          i18nKey={messages.acceptTerms}
        />
      </TextWithLink>
    </>
  )
}

ContactDataStep.propTypes = {
  handleCalculationSuccessful: PropTypes.func,
}

export const ContactDataImage = () => {
  const messages = useMessages()
  const { isMobile } = useIsMobile(Breakpoint.md)
  return (
    <ContactDataImageWrapper>
      <OfferReadyImageContainer>
        <ImageFromCdn fallbackType="jpg" name="offer-ready" type="jpg" />
      </OfferReadyImageContainer>
      {!isMobile && (
        <>
          <Typography variant="h4">
            {messages.contactDataImageText.title}
          </Typography>
          <Typography color={ThemeColor.b50} variant="captionR">
            {messages.contactDataImageText.subtitle}
          </Typography>
        </>
      )}
      <ImageFromCdn fallbackType="jpg" name="carrierBubbles" type="webp" />
    </ContactDataImageWrapper>
  )
}
