import { Breakpoint, ThemeColor, ThemeFontWeight } from '@/enums'
import { createBreakpoints } from './createBreakpoints'

export const theme = {
  color: {
    [ThemeColor.info1]: '#2137FC',
    [ThemeColor.info2]: '#6A79FD',
    [ThemeColor.info3]: '#E6E8FF',
    [ThemeColor.info4]: '#F5F6FF',
    [ThemeColor.success1]: '#0FA41E',
    [ThemeColor.success2]: '#1AB229',
    [ThemeColor.success3]: '#D2F0D5',
    [ThemeColor.success4]: '#F6FEF7',
    [ThemeColor.warning1]: '#FFB01A',
    [ThemeColor.warning2]: '#FFCA66',
    [ThemeColor.warning3]: '#FFE8BD',
    [ThemeColor.warning4]: '#FFFBF5',
    [ThemeColor.danger1]: '#FA0F17',
    [ThemeColor.danger2]: '#FC7377',
    [ThemeColor.danger3]: '#FECDCF',
    [ThemeColor.danger4]: '#FFF5F5',
    [ThemeColor.b100]: '#05070B',
    [ThemeColor.b50]: '#587393',
    [ThemeColor.b40]: '#A3B6CC',
    [ThemeColor.b30]: '#E4EAF1',
    [ThemeColor.b10]: '#F8FAFC',
    [ThemeColor.b0]: '#fff',
    [ThemeColor.electric400]: '#2137FC',
    [ThemeColor.electric500]: '#0317CE',
    [ThemeColor.electricText]: '#010B65',
  },
  font: {
    weight: {
      [ThemeFontWeight.light]: '200',
      [ThemeFontWeight.regular]: '400',
      [ThemeFontWeight.medium]: '500',
      [ThemeFontWeight.bold]: '700',
    },
  },
  baseFontSize: 4,
  breakpoints: createBreakpoints({
    [Breakpoint.xxs]: 0,
    [Breakpoint.xs]: 400,
    [Breakpoint.sm]: 600,
    [Breakpoint.tab]: 769,
    [Breakpoint.md]: 960,
    [Breakpoint.lg]: 1280,
    [Breakpoint.xl]: 1920,
  }),
  transition: 'cubic-bezier(0, 0, 0.2, 1)',
}
