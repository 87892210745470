import { TrustPartners, Typography, useImageUrlFromCdn } from '@/components'
import { Carousel, TestimonialCard } from '@surein/ui'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TestimonialGrid } from './styles'

const TextAlignTypography = styled(Typography)`
  text-align: center;
`
export const TestimonialSection = ({ isMobile }) => {
  const { t } = useTranslation()
  const testimonials = t('recommendationTestimonials', { returnObjects: true })
  const { imageUrlFromCdn } = useImageUrlFromCdn({
    type: 'webp',
    fallbackType: 'jpg',
  })

  return (
    <>
      <div className="flex flex-col items-center justify-center px-4 gap-6 mt-4">
        <TextAlignTypography bold className="mt-2" variant="h3">
          {t('earnedTheTrust')}
        </TextAlignTypography>
        <TrustPartners mode="dark" />
      </div>
      <TestimonialGrid>
        {isMobile ? (
          <Carousel startIndex="1">
            {testimonials.map((testimonial) => (
              <TestimonialCard
                key={testimonial.title}
                {...testimonial}
                imageUrl={imageUrlFromCdn(testimonial.imageUrl)}
              />
            ))}
          </Carousel>
        ) : (
          testimonials.map((testimonial) => (
            <TestimonialCard
              key={testimonial.title}
              {...testimonial}
              imageUrl={imageUrlFromCdn(testimonial.imageUrl)}
            />
          ))
        )}
      </TestimonialGrid>
    </>
  )
}

TestimonialSection.propTypes = {
  isMobile: PropTypes.bool,
}
