import { Row } from '@/components/styled'
import { Categories, ThemeColor } from '@/enums'
import { useSnackbar } from '@/providers'
import { useDeleteFromCart } from '@/services'
import { useLocationTypes } from '@/services/locationTypes'
import { theme } from '@/theme'
import {
  ddMMyyyy,
  formatMoney,
  propTypeFromEnumKeys,
  themeColor,
  toThemePixels,
  useTranslationSection,
  useTranslationSectionObject,
} from '@/utils'
import { Skeleton } from '@surein/ui'
import { Edit, Trash } from 'lucide-react'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Button, CarrierLogo, Typography } from '../../atoms'

export const LightTypography = styled(Typography)`
  font-weight: 400;
`

const ProductCardWrapper = styled.div`
  display: flex;
  padding: 16px;

  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  gap: 16px;

  border-radius: 12px;
  border: 1px solid ${themeColor(ThemeColor.b30)};
`

export const StyleTrashButton = styled(Button)`
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${themeColor(ThemeColor.danger4)};
  user-select: none;
  transition: 0.2s;
  padding: 12px;
  &:hover {
    border-color: ${ifProp('disabled', 'none', themeColor(ThemeColor.danger2))};
  }

  &:active {
    span {
      color: ${ifProp('disabled', 'none', themeColor(ThemeColor.b100))};
    }
  }
`

const ProductInfoSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 4px;
`

const MaxWidthWrapper = styled.div`
  display: flex;
  width: 100%;
`

const ProductCardHeader = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;

  img {
    background-color: ${themeColor(ThemeColor.b0)};
    border-radius: ${toThemePixels(2)};
    border: 1px solid ${themeColor(ThemeColor.b30)};
    height: ${toThemePixels(10)};
    width: ${toThemePixels(10)};

    @media (max-width: 900px) {
      height: ${toThemePixels(6)};
      width: ${toThemePixels(6)};
      border-radius: ${toThemePixels(1)};
    }
  }
`

const EditButton = styled(Button)`
  padding: 0;
  height: 1rem;
  width: 1rem;
`

const ProductCardTitle = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  gap: 8px;
`

const ProductCardItem = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

const useMessages = (category) => {
  const { t } = useTranslation()
  const categoriesTranslations = useTranslationSection('categories')
  const commonTranslations = useTranslationSectionObject('common')
  const recommendationsTranslations = useTranslationSectionObject(
    'recommendations',
  )
  const snackBars = useTranslationSection('snackbars')

  return {
    category: categoriesTranslations(`${category}.name`),
    description: categoriesTranslations(`${category}.shortDesc`),
    price: recommendationsTranslations.details.grossPrice,
    paymentPeriodOptions: t('paymentPeriodOptions', { returnObjects: true }),
    delete: commonTranslations.delete,
    begin: recommendationsTranslations.begins,
    deleteSuccess: snackBars('success.deleteSuccess'),
  }
}

export const CartCardBase = ({
  categoryId,
  name,
  logoUrl,
  startDate,
  grossPrice,
  risksInsured,
  paymentPeriod,
  recommendationProductId,
  handleOnEdit,
  onDelete,
}) => {
  const categoryData = Categories[categoryId]
  const messages = useMessages(categoryData.key)
  const { t } = useTranslation()
  const {
    data: locationTypesData,
    isLoading: locationTypesLoading,
  } = useLocationTypes()

  const handleDelete = (e) => {
    e.stopPropagation()
    onDelete()
  }

  return (
    <ProductCardWrapper>
      <ProductCardHeader>
        {logoUrl && <CarrierLogo name={logoUrl} />}
        <ProductCardTitle>
          <>
            <Typography bold color={ThemeColor.b100} variant="p2Body">
              {messages.category}
            </Typography>
            <LightTypography color={ThemeColor.b50} variant="inputDescription">
              {name}
            </LightTypography>
          </>
          <Row>
            <Typography bold variant="badgeText">
              {formatMoney(grossPrice)}
            </Typography>
            {paymentPeriod && (
              <Typography bold variant="badgeText">
                {` / ${messages.paymentPeriodOptions[paymentPeriod]}`.toLowerCase()}
              </Typography>
            )}
          </Row>
        </ProductCardTitle>
        {onDelete && (
          <StyleTrashButton onClick={handleDelete}>
            <Trash color={theme.color[ThemeColor.danger1]} size="16px" />
          </StyleTrashButton>
        )}
      </ProductCardHeader>

      <MaxWidthWrapper>
        <ProductInfoSection>
          <LightTypography color={ThemeColor.b50} variant="inputDescription">
            {t('checkout.titles.startDate')}
          </LightTypography>

          <ProductCardItem>
            <Typography bold color={ThemeColor.b100} variant="p2Body">
              {startDate ? ddMMyyyy(Date.parse(startDate)) : '-'}
            </Typography>
            {handleOnEdit && (
              <EditButton
                icon={Edit}
                onClick={() => handleOnEdit(recommendationProductId)}
                size="sm"
                variant="ghost"
              />
            )}
          </ProductCardItem>
        </ProductInfoSection>
        <ProductInfoSection>
          {risksInsured && (
            <>
              <LightTypography
                color={ThemeColor.b50}
                variant="inputDescription"
              >
                Risk insured
              </LightTypography>
              <Typography bold color={ThemeColor.b100} variant="p2Body">
                {locationTypesLoading ? (
                  <Skeleton />
                ) : (
                  locationTypesData?.getLocationLabel(risksInsured)
                )}
              </Typography>
            </>
          )}
        </ProductInfoSection>
      </MaxWidthWrapper>
    </ProductCardWrapper>
  )
}

CartCardBase.propTypes = {
  categoryId: propTypeFromEnumKeys(Categories).isRequired,
  grossPrice: PropTypes.string,
  handleOnEdit: PropTypes.func,
  logoUrl: PropTypes.string,
  name: PropTypes.string,
  onDelete: PropTypes.func,
  paymentPeriod: PropTypes.string,
  recommendationProductId: PropTypes.string,
  risksInsured: PropTypes.string,
  startDate: PropTypes.string,
}

export const CartCard = ({
  categoryId,
  name,
  carrierData,
  risksInsured,
  startDate,
  handleOnEdit,
  grossPrice,
  paymentPeriod,
  showDelete = true,
  recommendationProductId,
}) => {
  const snackbar = useSnackbar()
  const categoryData = Categories[categoryId]
  const messages = useMessages(categoryData.key)
  const createDeleteItemMutation = useDeleteFromCart()

  const handleDelete = () => {
    createDeleteItemMutation.mutateAsync(recommendationProductId).then(() => {
      snackbar.success(messages.deleteSuccess)
    })
  }

  return (
    <CartCardBase
      categoryId={categoryId}
      grossPrice={grossPrice}
      handleOnEdit={handleOnEdit}
      logoUrl={carrierData?.logoUrl}
      name={name}
      onDelete={showDelete && handleDelete}
      paymentPeriod={paymentPeriod}
      recommendationProductId={recommendationProductId}
      risksInsured={risksInsured}
      startDate={startDate}
    />
  )
}

CartCard.propTypes = {
  carrierData: PropTypes.objectOf({
    logoUrl: PropTypes.string,
  }),
  categoryId: propTypeFromEnumKeys(Categories).isRequired,
  grossPrice: PropTypes.string,
  handleOnEdit: PropTypes.func,
  name: PropTypes.string,
  paymentPeriod: PropTypes.string,
  recommendationProductId: PropTypes.string,
  risksInsured: PropTypes.string,
  showDelete: PropTypes.bool,
  startDate: PropTypes.string,
}
