import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { AssessmentLoadingStep } from './AssessmentLoadingStep'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    steps: [
      t('assessmentLoadingStep1'),
      t('liabilityAssessmentLoadingStep2'),
      t('liabilityAssessmentLoadingStep3'),
      t('assessmentLoadingStep5'),
    ],
    prepareOfferImageTitle: t('prepareOfferImageTitle'),
    prepareOfferImageContent: t('prepareOfferImageContent'),
  }
}

export const PrepareOfferStepB = ({ onNext }) => {
  const messages = useMessages()

  return (
    <AssessmentLoadingStep onNext={onNext} stepsMessages={messages.steps} />
  )
}

PrepareOfferStepB.propTypes = {
  onNext: PropTypes.func.isRequired,
}
