import { AssessmentStepKeys, BusinessOrgs } from '@/enums'
import { daysInFuture, getTomorrow, isString, snakeToCamelCase } from '@/utils'
import { values } from 'ramda'
import { isValidPhoneNumber } from 'react-phone-number-input'
import * as yup from 'yup'

export const makeValidationSchema = (messages) => ({
  [snakeToCamelCase(AssessmentStepKeys.userProfile)]: yup.object().shape({
    name: yup.string().required(messages.required),
    phone: yup
      .string()
      .test('isValid', messages.phoneIncorrect, (val) =>
        isValidPhoneNumber(`+${val}`),
      ),
    surname: yup.string().required(messages.required),
  }),
  [snakeToCamelCase(AssessmentStepKeys.createAccount)]: yup.object().shape({
    email: yup.string().email(messages.validEmail).required(messages.required),
    name: yup.string().required(messages.required),
    phone: yup
      .string()
      .test('isValid', messages.phoneIncorrect, (val) =>
        isValidPhoneNumber(`+${val}`),
      ),
  }),
  [snakeToCamelCase(AssessmentStepKeys.createAccountB)]: yup.object().shape({
    phone: yup
      .string()
      .test('isValid', messages.phoneIncorrect, (val) =>
        isValidPhoneNumber(`+${val}`),
      ),
  }),
  [snakeToCamelCase(AssessmentStepKeys.createLead)]: yup.object().shape({
    address: yup.object().shape({
      postalCode: yup.string(),
      street: yup.string(),
      streetNr: yup.string(),
    }),
    email: yup.string().email(messages.validEmail).required(messages.required),
    name: yup.string().required(messages.required),
    phone: yup
      .string()
      .test('isValid', messages.phoneIncorrect, (val) =>
        isValidPhoneNumber(`+${val}`),
      ),
  }),
  [snakeToCamelCase(AssessmentStepKeys.contactData)]: yup.object().shape({
    email: yup.string().email(messages.validEmail).required(messages.required),
    name: yup.string().required(messages.required),
  }),
  [snakeToCamelCase(AssessmentStepKeys.businessType)]: yup.object().shape({
    businessType: yup
      .string()
      .required(messages.required)
      .test({
        name: 'isValid',
        message: messages.required,
        test: (val) => Boolean(val),
      }),
    locationSubtypes: yup.array().of(yup.string()),
    locationType: yup.string(),
    locationTypeSpecification: yup.string().when('locationType', {
      is: (locationType) => locationType === 'other',
      then: yup.string().required(messages.required),
      otherwise: yup.string(),
    }),
  }),
  [snakeToCamelCase(AssessmentStepKeys.companyDetails)]: yup.object().shape({
    address: yup
      .object()
      .shape({
        city: yup.string().required(messages.city),
        country: yup.string().required(messages.country),
        postalCode: yup.string().required(messages.postalCode),
        street: yup.string().required(messages.street),
        streetNr: yup.string().required(messages.streetNr),
      })
      .typeError(messages.selectOption),
    name: yup
      .string()
      .required(messages.name)
      .test(
        'isValid',
        messages.required,
        (val) =>
          !values(BusinessOrgs).some(
            (businessOrg) => businessOrg === val.trim(),
          ),
      ),
  }),
  [snakeToCamelCase(AssessmentStepKeys.companyFoundedDate)]: yup
    .object()
    .shape({
      foundedDate: yup.lazy((value) => {
        if (isString(value)) return yup.string().required(messages.required)

        return yup.number().required(messages.required)
      }),
    }),
  [snakeToCamelCase(AssessmentStepKeys.startDate)]: yup.object().shape({
    startDate: yup.lazy((value) => {
      if (isString(value)) return yup.string().required(messages.required)

      return yup
        .number()
        .required(messages.required)
        .test(
          'isValidDate',
          messages.startDateNotValidLe,
          (startDate) =>
            getTomorrow().setHours(0, 0, 0, 0) <= startDate &&
            startDate <= daysInFuture(90).setHours(0, 0, 0, 0),
        )
    }),
  }),
  [snakeToCamelCase(
    AssessmentStepKeys.companyFoundedDateLead,
  )]: yup.object().shape({
    foundedDate: yup.lazy((value) => {
      if (isString(value)) return yup.string().required(messages.required)

      return yup
        .number()
        .required(messages.required)
        .typeError(messages.required)
    }),
  }),
  [snakeToCamelCase(AssessmentStepKeys.locationsCount)]: yup.object().shape({
    locationsCount: yup
      .number()
      .required(messages.required)
      .min(1, messages.makeMin(1))
      .typeError(messages.required),
  }),
  [snakeToCamelCase(AssessmentStepKeys.locationDetails)]: yup.object().shape({
    address: yup
      .object()
      .shape({
        city: yup.string().required(messages.city),
        country: yup.string().required(messages.country),
        postalCode: yup.string().required(messages.postalCode),
        street: yup.string().required(messages.street),
        streetNr: yup.string().required(messages.streetNr),
      })
      .typeError(messages.selectOption),
    name: yup.string().required(messages.name),
  }),
  [snakeToCamelCase(AssessmentStepKeys.locationOpenSeason)]: yup
    .object()
    .shape({
      openSeason: yup.string().required(messages.required),
    }),
  [snakeToCamelCase(AssessmentStepKeys.employeesCount)]: yup.object().shape({
    fullTimeEmployeesCount: yup.number().min(0, messages.positive),
    miniJobbersCount: yup.number().min(0, messages.positive),
    ownersCount: yup
      .number()
      .required(messages.required)
      .min(1, messages.positive)
      .typeError(messages.required),
    partTimeEmployeesCount: yup.number().min(0, messages.positive),
  }),
  [snakeToCamelCase(AssessmentStepKeys.lastYearFinances)]: yup.object().shape({
    approxCostOfGoods: yup
      .number()
      .required(messages.required)
      .min(0, messages.positive)
      .typeError(messages.required),
    approxPersonelExpenses: yup
      .number()
      .required(messages.required)
      .min(0, messages.positive)
      .typeError(messages.required),
    approxTurnover: yup
      .number()
      .required(messages.required)
      .min(0, messages.positive)
      .typeError(messages.required),
  }),
  [snakeToCamelCase(AssessmentStepKeys.approxRevenue)]: yup.object().shape({
    approxTurnover: yup
      .number()
      .required(messages.approxHelperText)
      .min(0, messages.positive)
      .typeError(messages.approxHelperText),
  }),
  [snakeToCamelCase(AssessmentStepKeys.approxLaborCosts)]: yup.object().shape({
    approxPersonelExpenses: yup
      .number()
      .required(messages.approxHelperText)
      .min(0, messages.positive)
      .typeError(messages.approxHelperText),
  }),
  [snakeToCamelCase(AssessmentStepKeys.locationActivities)]: yup
    .object()
    .shape({
      essentialCategoryId: yup.array().of(yup.string()),
    }),
  [snakeToCamelCase(AssessmentStepKeys.locationRent)]: yup.object().shape({
    annualBruttoRent: yup
      .number()
      .min(0, messages.positive)
      .typeError(messages.required),
    rentedArea: yup
      .number()
      .min(0, messages.positive)
      .typeError(messages.required),
    totalEquipmentsCost: yup
      .number()
      .required(messages.required)
      .min(0, messages.positive)
      .typeError(messages.required),
  }),
  [snakeToCamelCase(AssessmentStepKeys.currentInsurances)]: yup.object().shape({
    insurances: yup
      .array()
      .of(
        yup.object().shape({
          carrierId: yup.string().required(messages.required),
          categoryId: yup.string().required(messages.required),
        }),
      )
      .required(messages.required),
  }),
  [snakeToCamelCase(AssessmentStepKeys.powerOfAttorney)]: yup.object().shape({
    birthdate: yup.lazy((value) => {
      if (isString(value)) return yup.string().required(messages.required)

      return yup.number().required(messages.required)
    }),
    signature: yup.string().required(messages.required),
  }),
  [snakeToCamelCase(AssessmentStepKeys.howCanWeHelp)]: yup.object().shape({
    lookingFor: yup.array().of(yup.string()),
  }),
  [snakeToCamelCase(AssessmentStepKeys.howCanWeHelpLead)]: yup.object().shape({
    lookingFor: yup.array().of(yup.string()),
  }),
  [snakeToCamelCase(AssessmentStepKeys.inventory)]: yup.object().shape({
    totalEquipmentsCost: yup
      .number()
      .required(messages.required)
      .min(0, messages.positive)
      .typeError(messages.required),
  }),
  [snakeToCamelCase(
    AssessmentStepKeys.constructionDetails,
  )]: yup.object().shape({
    constructionValue: yup
      .number()
      .required(messages.required)
      .min(0, messages.positive)
      .typeError(messages.required),
  }),
  [snakeToCamelCase(AssessmentStepKeys.constructionArea)]: yup.object().shape({
    constructionArea: yup
      .number()
      .required(messages.required)
      .min(0, messages.positive)
      .typeError(messages.required),
  }),
  [snakeToCamelCase(AssessmentStepKeys.constructionDate)]: yup.object().shape({
    constructionDate: yup.lazy((value) => {
      if (isString(value)) return yup.string().required(messages.required)

      return yup
        .number()
        .required(messages.required)
        .typeError(messages.required)
    }),
  }),
})
