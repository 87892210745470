import * as Yup from 'yup'
import { CheckoutStepKeys } from './steps'

export const makeValidationSchema = (messages) => ({
  [CheckoutStepKeys.payment]: Yup.object().shape({
    payment: Yup.object().required(messages.general),
  }),
  [CheckoutStepKeys.poa]: Yup.object().shape({
    // TODO: Uncomment when birthdate is able to be validated
    // birthdate: Yup.date()
    //   .typeError(messages.validDate)
    //   .required(messages.general),
    signature: Yup.string().required(messages.general),
  }),
})
