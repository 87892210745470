import { Header, Loader, Typography } from '@/components'
import { config } from '@/config'
import { ThemeColor } from '@/enums'
import {
  makeBoughtProductAnalyticsPayload,
  makeRecommendationAnalyticsPayload,
  useSegment,
} from '@/modules/analytics'
import { paths } from '@/routes/paths'
import {
  useCreateOrRetrieveConditionalRecommendation,
  useDownloadPublicFile,
  useUpdatePublicCart,
} from '@/services'
import { theme } from '@/theme'
import {
  loadDesktopHubspotChat,
  noop,
  pushEventToDataLayer,
  useGoToPath,
  useLanguage,
} from '@/utils'
import { Check } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import {
  useForwardFunnelProduct,
  useForwardFunnelSource,
} from '../SelfAssessmentPage/helpers'
import { SuccessPage } from '../SelfAssessmentPage/public/SuccessPage'
import { BasePage } from './BasePage'
import { getStoredRecommendationId, storeRecommendationId } from './helpers'
import { HeaderArea, HeaderCancellationNotice } from './styles'

export const ConditionalRecommendation = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { categoryId } = useParams()

  if (!categoryId) {
    throw new Error('categoryId is required')
  }

  const language = useLanguage()
  const storedRecommendationId = getStoredRecommendationId(categoryId)
  const analytics = useSegment()

  const {
    data,
    isLoading,
    isError,
  } = useCreateOrRetrieveConditionalRecommendation(storedRecommendationId)

  const [noticeHeight, setNoticeHeight] = useState(0)

  const cancellationNoticeRef = useCallback((node: HTMLDivElement) => {
    if (!node) return
    const resizeObserver = new ResizeObserver(() => {
      setNoticeHeight(node.offsetHeight)
    })
    resizeObserver.observe(node)
  }, [])

  const { id: recommendationId, recommendation, assessmentValues } = data || {}

  useEffect(() => {
    // Avoid overwriting the stored recommendation id with undefined
    if (recommendationId === undefined) return
    storeRecommendationId(categoryId, recommendationId)
  }, [recommendationId])

  const queryParams = useForwardFunnelSource()
  const { funnel_product: funnelProduct } = useForwardFunnelProduct()

  const goToCheckout = useGoToPath(
    paths.viewSsfCheckoutWithKey(categoryId),
    // when funnel source is not a concern, this will be transformed to
    // just forward the funnel_product instead of spreading the query params
    { ...queryParams, funnel_product: funnelProduct },
    { backPathname: pathname },
  )

  useEffect(() => {
    loadDesktopHubspotChat()
  }, [])

  useEffect(() => {
    if (isLoading || !recommendation?.isSuccessful) return
    analytics.track(
      'ssf_recommendation',
      makeRecommendationAnalyticsPayload(data),
    )
  }, [recommendationId, recommendation])

  const updateCartMutation = useUpdatePublicCart()
  const downloadFileMutation = useDownloadPublicFile()

  const handleFileClick = ({ fileId }: { fileId: string }) => {
    downloadFileMutation.mutate({
      fileId,
      confirmationId: recommendationId!,
    })
  }

  const handleChoose = (recommendationProductId: string) =>
    updateCartMutation.mutate(recommendationProductId, {
      onSuccess: async () => {
        pushEventToDataLayer('Step Change', {
          funnel_step: 'recommendation_product_chosen',
          funnel_product: funnelProduct,
        })
        await analytics.track(
          'ssf_recommendation_bought',
          makeBoughtProductAnalyticsPayload(recommendationProductId, data),
        )
        goToCheckout()
      },
    })

  useEffect(() => {
    if (recommendation?.isSuccessful === false || isError) {
      analytics.track('ssf_recommendation_lead_gen')
      window.location.replace(
        language === 'en'
          ? config.failedCalculationLinkEn
          : config.failedCalculationLinkDe,
      )
    }
  }, [recommendation, isError])

  if (isLoading) return <Loader />

  if (recommendation?.isSuccessful === false || isError || !recommendation) {
    return <SuccessPage onLogoClick={noop} />
  }

  return (
    <>
      <HeaderArea headerOffset={noticeHeight}>
        <HeaderCancellationNotice ref={cancellationNoticeRef}>
          <Check color={theme.color[ThemeColor.success1]} />
          <Typography color={ThemeColor.b0} variant="p2Body">
            {t('recommendationCancellationNotice')}
          </Typography>
        </HeaderCancellationNotice>
        <Header onLogoClick={noop} />
      </HeaderArea>
      <BasePage
        assessmentValues={assessmentValues}
        bannerOffset={noticeHeight}
        data={recommendation}
        handleFileClick={handleFileClick}
        noBack
        onChoose={handleChoose}
      />
    </>
  )
}
