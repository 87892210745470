import { isImgTypeSupported } from '@/utils'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { config } from '../../../config'

export const Image = ({ name, type = 'svg', fallbackType, ...props }) => {
  const [ext, setExt] = useState(type)

  useEffect(() => {
    isImgTypeSupported(type).then((supported) => {
      if (!supported) setExt(fallbackType)
    })
  }, [])

  return <img alt={`${name} icon`} src={`/assets/${name}.${ext}`} {...props} />
}

export const ImageFromCdn = ({
  name,
  type = 'png',
  fallbackType,
  ...props
}) => {
  const [ext, setExt] = useState(type)

  useEffect(() => {
    isImgTypeSupported(type).then((supported) => {
      if (!supported) setExt(fallbackType)
    })
  }, [])

  return (
    <img alt={`${name} img`} src={`${config.cdn}/${name}.${ext}`} {...props} />
  )
}

export const useImageUrlFromCdn = ({ type = 'png', fallbackType }) => {
  const [ext, setExt] = useState(type)

  useEffect(() => {
    isImgTypeSupported(type).then((supported) => {
      if (!supported) setExt(fallbackType)
    })
  }, [])

  const imageUrlFromCdn = (name) => `${config.cdn}/${name}.${ext}`

  return { imageUrlFromCdn }
}

Image.propTypes = {
  fallbackType: PropTypes.oneOf(['svg', 'png', 'jpg', 'jpeg', 'gif', 'webp']),
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['svg', 'png', 'jpg', 'jpeg', 'gif', 'webp']),
}

ImageFromCdn.propTypes = {
  fallbackType: PropTypes.oneOf(['svg', 'png', 'jpg', 'jpeg', 'gif', 'webp']),
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['svg', 'png', 'jpg', 'jpeg', 'gif', 'webp']),
}
