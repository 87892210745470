import { ThemeColor } from '@/enums'
import { useInsuranceGroups } from '@/modules/insurances/hooks/useInsuranceGroups'
import { FormProvider, useFormItem } from '@/providers'
import { useCreateMultipleInsurances } from '@/services'
import {
  themeColorPropType,
  useTranslationSection,
  useTranslationSectionObject,
} from '@/utils'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { BackComponent, PrimaryButton, Typography } from '../../atoms'
import { Column } from '../../styled'
import { FormGroupCardWithSelectModal } from '../GroupCardSelectWithModal'
import { Container, PageActions } from './styles'
import { validationSchema } from './validationSchema'

const useMessages = (search) => {
  const { t } = useTranslation()
  const commonTranslations = useTranslationSection('common')
  const locationTranslations = useTranslationSection('locationPage')
  const pageTranslations = useTranslationSection('addInsurance')

  return {
    back: commonTranslations('back'),
    addInsurance: locationTranslations('addInsurance'),
    addPolicy: pageTranslations('addPolicy'),
    title: pageTranslations('title'),
    prompt: pageTranslations('prompt'),
    skip: pageTranslations('skip'),
    skipHint: pageTranslations('skipHint'),
    validations: useTranslationSectionObject('validations'),
    selectProvider: t('selectProvider'),
    selectProviderDescription: t('selectProviderDescription'),
    change: t('change'),
    noInsuranceFound: t('yearlyAssessment.noInsuranceFound'),
    noInsuranceFoundDescription: t(
      'yearlyAssessment.noInsuranceFoundDescription',
      { search },
    ),
    searchProvider: t('searchProvider'),
  }
}

const AddInsuranceForm = () => {
  const { value } = useFormItem('insurances')
  const { carriers } = useOutletContext()
  const messages = useMessages()
  const cardOptions = useInsuranceGroups()

  return (
    <>
      <FormGroupCardWithSelectModal
        cardKey="categoryId"
        cardOptions={cardOptions}
        initialValue={[]}
        listKey="carrierId"
        listOptions={carriers}
        modalSubtitle={messages.selectProviderDescription}
        modalTitle={messages.selectProvider}
        name="insurances"
        resetText={messages.change}
        searchPlaceholder={messages.searchProvider}
      />
      <PageActions>
        <PrimaryButton disabled={!value?.length} type="submit">
          {messages.addPolicy}
        </PrimaryButton>
      </PageActions>
    </>
  )
}

export const AddInsuranceView = ({
  onBack,
  onSuccess,
  locationId,
  textColor = ThemeColor.b0,
}) => {
  const messages = useMessages()
  const createMultipleInsurancesMutation = useCreateMultipleInsurances(
    locationId,
  )

  const handleSubmit = ({ insurances }) =>
    createMultipleInsurancesMutation.mutateAsync(insurances).then(onSuccess)

  return (
    <Container>
      <Column gap="16px">
        <BackComponent noContentGutter onBack={onBack} />
        <Typography color={textColor} responsive variant="h3">
          {messages.title}
        </Typography>
        <Typography color={textColor} variant="captionR">
          {messages.prompt}
        </Typography>
      </Column>
      <FormProvider
        onSubmit={handleSubmit}
        validationSchema={validationSchema(messages.validations)}
      >
        <AddInsuranceForm />
      </FormProvider>
    </Container>
  )
}

AddInsuranceView.propTypes = {
  locationId: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  textColor: themeColorPropType,
}
