import { useEffect, useState } from 'react'
import { useModal } from './useModal'
import { useToggable } from './useToggable'

export const useAnimateEffect = ({ onLeave, duration, defaultOpened }) => {
  const { isOpen, close } = useModal(defaultOpened)
  const [leaving, , setLeaving] = useToggable(false)

  useEffect(() => {
    const animate = setTimeout(setLeaving, duration)
    const lifestamp = setTimeout(() => {
      close()
      onLeave()
    }, duration + 200)

    return () => {
      clearTimeout(animate)
      clearTimeout(lifestamp)
    }
  }, [])

  return { isOpen, leaving }
}

export const useAnimatedAction = ({ onLeave, duration = 400 }) => {
  const [leaving, setLeaving] = useState()

  const leave = () => {
    setLeaving(true)
    document.getElementById('steps').style.transform = 'translateX(100%)'
    setTimeout(() => {
      setLeaving(false)
      setTimeout(onLeave)
    }, duration)
  }

  return [leaving, leave]
}

export const useAnimatedModal = ({ onLeave, duration, defaultOpened }) => {
  const { isOpen, open, close } = useModal(defaultOpened)
  const [leaving, , toggleLeave] = useToggable(false)

  const leave = () => {
    toggleLeave()
    setTimeout(() => {
      close()
      onLeave()
      toggleLeave()
    }, duration)
  }

  return { isOpen, leaving, leave, open }
}
