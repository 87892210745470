import { Column, FormNumber } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import {
  useContactModal,
  useFormContext,
  useNextDisabled,
  useNextLoading,
  useOnNextClick,
  useSetButtonText,
} from '@/providers'
import { Alert } from '@surein/ui'
import { InfoIcon } from 'lucide-react'
import { isEmpty } from 'ramda'
import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSaveAssessmentStep } from '../helpers'
import { StyledLinkTypography } from './styles'

export const useEmployeeCountMessages = () => {
  const { t } = useTranslation()

  return {
    owners: t('employeesCountOwners'),
    fullTime: t('employeesCountFullTime'),
    partTime: t('employeesCountpartTime'),
    miniJobbers: t('employeesCountMiniJobbers'),
    fullTimeInfo: t('fullTimeInfo'),
    partTimeInfo: t('partTimeInfo'),
    miniJobbersInfo: t('miniJobbersInfo'),
    employeesCountError: t('employeesCountError'),
    employeesCountAdvisorHint: t('employeesCountAdvisorHint'),
  }
}

export const EmployeesCountStep = () => {
  const { t } = useTranslation()
  const messages = useEmployeeCountMessages()
  const { values, errors } = useFormContext()
  const { openContact } = useContactModal()
  const { save, isLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.employeesCount,
  )

  useOnNextClick(save)

  const isButtonDisabled = useCallback(() => values.ownersCount === 0, [values])

  useNextDisabled(() => isButtonDisabled(), [values])

  useSetButtonText(
    () =>
      isButtonDisabled()
        ? t('stepperButtonTextOptions.employeesCount')
        : t('next'),
    [values],
  )

  useNextLoading(() => isLoading, [isLoading])

  return (
    <Column gap="24px">
      {!isEmpty(errors) && (
        <Alert
          icon={InfoIcon}
          iconAlignment="top"
          text={
            <Trans
              components={
                <StyledLinkTypography
                  onClick={openContact}
                  variant="captionB"
                />
              }
              i18nKey={messages.employeesCountError}
              variant="danger"
            />
          }
          variant="danger"
        />
      )}
      <Column>
        <FormNumber
          initialValue={0}
          label={messages.owners}
          min={0}
          name="ownersCount"
          showErrorHelperText={false}
        />
        <FormNumber
          initialValue={0}
          label={messages.fullTime}
          min={0}
          name="fullTimeEmployeesCount"
          showErrorHelperText={false}
        />
        <FormNumber
          initialValue={0}
          label={messages.partTime}
          min={0}
          name="partTimeEmployeesCount"
          showErrorHelperText={false}
        />
        <FormNumber
          initialValue={0}
          label={messages.miniJobbers}
          min={0}
          name="miniJobbersCount"
          noMargin
          showErrorHelperText={false}
        />
      </Column>
    </Column>
  )
}
