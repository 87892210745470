import { ThemeColor } from '@/enums'
import { GoogleRatingIcon } from '@/icons'
import PropTypes from 'prop-types'
import { range } from 'ramda'
import { Column } from '../../styled'
import {
  Container,
  Google,
  GoogleRating,
  Rating,
  Ssl,
  StyledGoogleIcon,
  StyledSslIcon,
  StyledSslTextIcon,
  StyledSureinTrustLogo,
  StyledTrustPilotIcon,
  StyledTrustPilotStarIcon,
} from './styles'

const colors = {
  light: {
    primary: ThemeColor.b0,
    secondary: ThemeColor.b0,
  },
  dark: {
    primary: ThemeColor.b100,
    secondary: ThemeColor.b50,
  },
}

export const TrustPartners = ({ mode = 'light', hide = {} }) => (
  <Container>
    <Column>
      <StyledTrustPilotIcon color={colors[mode].primary} />
      <StyledTrustPilotStarIcon />
    </Column>
    <Google>
      <StyledGoogleIcon />
      <GoogleRating>
        {range(1, 6).map((item) => (
          <GoogleRatingIcon
            key={item}
            color={colors[mode].secondary}
            size="menu"
          />
        ))}
        <Rating color={colors[mode].secondary}>5.0</Rating>
      </GoogleRating>
    </Google>
    <Ssl>
      <StyledSslIcon />
      <Column>
        <StyledSslTextIcon color={colors[mode].primary} />
      </Column>
    </Ssl>
    {!hide.surein && <StyledSureinTrustLogo />}
  </Container>
)

TrustPartners.propTypes = {
  hide: PropTypes.shape({ surein: PropTypes.bool }),
  mode: PropTypes.oneOf(['light', 'dark']),
}
