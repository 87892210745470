import { ThemeColor } from '@/enums'
import { Close } from '@/icons'
import { forwardRef, useEffect } from 'react'
import { Image, PrimaryButton, SecondaryButton } from '../../atoms'
import { WithMask } from '../../hoc/WithMask'
import {
  CloseModalButton,
  Container,
  EmojiContainer,
  ModalActions,
  ModalContent,
  ModalDetails,
  ModalHeader,
  ModalSubHeader,
} from './styles'

export const maybeRenderInModal = ({
  Component,
  modalProps,
  shouldRenderInModal,
  componentProps = {},
}: {
  Component: React.ComponentType<any>
  modalProps: ModalBaseProps
  shouldRenderInModal: boolean
  // TODO: fix any type
  componentProps?: any
}) =>
  shouldRenderInModal ? (
    <ModalBase {...modalProps}>
      <Component {...componentProps} />
    </ModalBase>
  ) : (
    <Component {...componentProps} />
  )

interface ModalBaseProps {
  absolute?: boolean
  isOpen: boolean
  children?: React.ReactNode
  closable?: boolean
  onClose: () => void
  header?: React.ReactNode
  subheader?: React.ReactNode
  onPrimaryClick?: () => void
  primaryButtonType?: 'button' | 'submit' | 'reset'
  primaryDisabled?: boolean
  primaryText?: string
  secondaryText?: string
  emoji?: string
  fullscreen?: boolean
  photo?: string
  padding?: string
  enableClickOutside?: boolean
  shouldRenderInPortal?: boolean
  noAnimation?: boolean
}

export const ModalBase = forwardRef(
  (
    {
      absolute,
      isOpen,
      children,
      closable,
      onClose,
      header,
      subheader,
      onPrimaryClick,
      primaryButtonType,
      primaryDisabled,
      primaryText,
      secondaryText,
      emoji,
      fullscreen,
      photo,
      padding,
      enableClickOutside = true,
      shouldRenderInPortal = true,
      noAnimation,
    }: ModalBaseProps,
    ref?: React.Ref<HTMLDivElement>,
  ) => {
    const close = (e: { keyCode: number }) => {
      if (e.keyCode === 27) {
        onClose()
      }
    }

    useEffect(() => {
      window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
    }, [])

    const withButtons = primaryText || secondaryText

    return (
      <WithMask
        absolute={absolute}
        closable={enableClickOutside && closable}
        isOpen={isOpen}
        noAnimation={noAnimation}
        onClose={onClose}
        shouldRenderInPortal={shouldRenderInPortal}
      >
        <Container
          ref={ref}
          absolute={absolute}
          fullscreen={fullscreen}
          noAnimation={noAnimation}
          padding={padding}
        >
          {onClose && (
            <CloseModalButton onClick={onClose}>
              <Close color={ThemeColor.b100} height={16} width={16} />
            </CloseModalButton>
          )}
          {emoji && <EmojiContainer>{emoji}</EmojiContainer>}
          {photo && <Image name={photo} type="png" fallbackType="png" />}
          {(header || subheader) && (
            <ModalDetails fullscreen={fullscreen}>
              {header && <ModalHeader>{header}</ModalHeader>}
              {subheader && <ModalSubHeader>{subheader}</ModalSubHeader>}
            </ModalDetails>
          )}
          {children && (
            <ModalContent fullscreen={fullscreen} withButtons={withButtons}>
              {children}
            </ModalContent>
          )}
          {withButtons && (
            <ModalActions singleButton={!secondaryText}>
              {primaryText && (
                <PrimaryButton
                  // @ts-expect-error TODO: migrate to toolkit
                  block
                  disabled={primaryDisabled}
                  onClick={onPrimaryClick}
                  size="large"
                  type={primaryButtonType}
                  variant="contained"
                >
                  {primaryText}
                </PrimaryButton>
              )}
              {secondaryText && (
                <SecondaryButton
                  // @ts-expect-error TODO: migrate to toolkit
                  block
                  onClick={onClose}
                  size="large"
                  variant="text"
                >
                  {secondaryText}
                </SecondaryButton>
              )}
            </ModalActions>
          )}
        </Container>
      </WithMask>
    )
  },
)

ModalBase.displayName = 'ModalBase'
