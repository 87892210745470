import { api } from './api'

export interface GetRecommendationByIdApiResponse {
  categoryId: string
  isSearchable?: boolean
  searchCriteria?: Record<string, any> // TODO: fix type
  userSearchCriteria?: Record<string, any> | null // TODO: fix type
  visibleAttributes: string[]
  bestChoice: string
  locationId: string
  recommendationProducts: {
    categoryId: string
    amountInsured: number
    deductible: number
    recommendationProductId: string
    parametersValues: {
      de: Record<string, string>
      en: Record<string, string>
    }
    yesNoValues: Record<string, 0 | 1>
    grossPrice: number
    paymentPeriod: string
    carrierId: string
    carrierData: {
      logoUrl: string
    }
    discountValue: number
    recommendationProductFiles: { fileId: string; name: string }[]
    amountInsuredUnlimited: boolean
    offerTag?: string | null
    order: number
  }[]
}

export const getRecommendationById = (
  id: string,
): Promise<GetRecommendationByIdApiResponse> =>
  api.get(`/user/recommendation/${id}`)

export const requestRecommendation = ({
  categoryId,
  locationId,
  message,
}: {
  categoryId: string
  locationId: string
  message?: string
}) => api.post('/user/recommendation', { categoryId, locationId, message })
