import { ComponentColors } from '@/enums'
import { cleanPercentage, componentColorPropType, rangePropType } from '@/utils'
import PropTypes from 'prop-types'
import { Container, InnerContainer } from './styles'

export const LineProgress = ({
  percentage,
  height = '4px',
  width,
  color = ComponentColors.text,
}) => (
  <Container color={color} height={height} width={width}>
    <InnerContainer
      color={color}
      height={height}
      width={cleanPercentage(percentage)}
    />
  </Container>
)

LineProgress.propTypes = {
  color: componentColorPropType,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  percentage: rangePropType(0, 100),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
