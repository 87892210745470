import {
  authInterceptor,
  createMultiTenantApi,
  unauthorizedInterceptor,
} from '@/utils'
import { identity } from 'ramda'
import { config } from '../config'
import { createApiClient } from '@/utils/apiClient'

export const api = createMultiTenantApi(config.backend)
export const newApi = createApiClient(config.backend)

export const setAuthToken = (token: string) => {
  api.interceptors.request.use(authInterceptor(token))
  newApi.interceptors.request.use(authInterceptor(token))
}

export const setUnauthorizedInterceptor = (logout: () => void) => {
  api.interceptors.response.use(identity, unauthorizedInterceptor(logout))
  newApi.interceptors.response.use(identity, unauthorizedInterceptor(logout))
}
