import { getLocationTypes } from '@/api'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

export const useLocationTypes = () => {
  const { i18n } = useTranslation()

  return useQuery('location-types', () => getLocationTypes(i18n.language), {
    staleTime: Infinity,
    cacheTime: Infinity,
  })
}
