import { useTranslation } from 'react-i18next'

export const useApiErrorMessage = () => {
  const { t } = useTranslation()

  const messages = t('apiErrors', { returnObjects: true })

  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'USER_ALREADY_EXISTS':
        return messages.USER_ALREADY_EXISTS
      default:
        return messages.DEFAULT
    }
  }

  return { getErrorMessage, errorMessages: messages }
}
