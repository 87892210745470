import { Column, Typography } from '@/components'
import {
  findBusinessType,
  FormAutoCompleteItem,
} from '@/components/molecules/FormItem/FormAutocompleteItem'
import { AssessmentStepKeys, Breakpoint, ThemeColor } from '@/enums'
import { useSegment } from '@/modules'
import { useSaveAssessmentStep } from '@/pages/SelfAssessmentPage/helpers'
import {
  useFormContext,
  useNextDisabled,
  useOnNextClick,
  useSetButtonText,
} from '@/providers'
import { useLocationTypes } from '@/services/locationTypes'
import { useIsMobile } from '@/utils'
import { Alert, Button } from '@surein/ui'
import { InfoIcon, Plus } from 'lucide-react'
import { isEmpty, omit } from 'ramda'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

const MixedBusinessWrapper = styled(Column)`
  gap: 16px;
`

const PrimaryActivityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`

const SecondaryActivityWrapper = styled(Column)`
  gap: 8px;
`

const HIDDEN_OPTIONS = ['online']

const makeDisabled = ({ locationType = '', locationTypeSpecification }) =>
  !locationType && !locationTypeSpecification

export const BusinessTypeStep = () => {
  const { t } = useTranslation()
  const {
    data: locationTypesData,
    isLoading: locationTypesLoading,
  } = useLocationTypes()

  const { isMobile } = useIsMobile(Breakpoint.sm)
  const { setFieldValue, values, errors, setErrors } = useFormContext()
  const [secondarySelections, setSecondarySelections] = useState([])
  const { save } = useSaveAssessmentStep(AssessmentStepKeys.businessType)
  const analytics = useSegment()

  const { verticalsMap = {}, getLocationLabel, getVerticalLabel } =
    locationTypesData || {}

  const makeOptions = () =>
    Object.keys(omit(['other'], verticalsMap)).flatMap((value) =>
      verticalsMap[value].map((item) => ({
        value: item,
        label: getLocationLabel(item),
        subtitle: getVerticalLabel(value),
      })),
    )

  const options = useMemo(() => makeOptions(), [locationTypesData])

  useEffect(() => {
    if (values.locationSubtypes) {
      const secondarySubtypes = values.locationSubtypes
      setSecondarySelections(
        secondarySubtypes.map((subtype) => ({
          id: uuidv4(),
          value: subtype,
        })),
      )
    }
  }, [values.locationSubtypes])

  const handleAddSecondary = () => {
    const allFilled = secondarySelections.every(
      (selection) => !!selection.value,
    )

    if (allFilled && secondarySelections.length < 3) {
      const newId = uuidv4()
      setSecondarySelections([
        ...secondarySelections,
        { id: newId, value: undefined },
      ])
    }
  }

  const handleSelectSecondary = useCallback(
    (option, id) => {
      const newSelections = secondarySelections.map((item) =>
        item.id === id
          ? { ...item, value: option.value, label: option.label }
          : item,
      )

      analytics.track('secondary_risk', {
        activity: option.value,
      })

      setSecondarySelections(newSelections)

      const locationSubtypes = newSelections.map((item) => item.value)

      setFieldValue('locationSubtypes', locationSubtypes)
    },
    [secondarySelections, setFieldValue, values.locationSubtypes],
  )

  const handleClearSecondary = useCallback(
    (id) => {
      const filtered = secondarySelections.filter((item) => item.id !== id)
      setSecondarySelections(filtered)

      const locationSubtypes = filtered.map((item) => item.value)

      setFieldValue('locationSubtypes', locationSubtypes)
    },
    [secondarySelections, setFieldValue, values.locationSubtypes],
  )

  const handleSelectPrimary = useCallback(
    (option) => {
      if (!option) return

      const businessType = findBusinessType(
        option,
        locationTypesData?.verticalsMap ?? {},
        locationTypesData?.getVerticalLabel,
      ).value

      if (option.created || businessType === 'other') {
        setFieldValue('locationTypeSpecification', option.value)
        setFieldValue('locationType', '')
      } else {
        setFieldValue('locationType', option.value)
        setFieldValue('locationTypeSpecification', '')
      }

      setFieldValue('businessType', businessType)

      analytics.track('main_risk', {
        activity: option.value,
      })

      if (errors && errors.businessType) {
        setErrors({})
      }
    },
    [setFieldValue, locationTypesData, errors, setErrors],
  )

  const handleClearPrimary = useCallback(() => {
    setFieldValue('businessType', '')
    setFieldValue('locationType', '')
    setFieldValue('locationTypeSpecification', '')
  }, [setFieldValue])

  const saveAndTrack = useCallback(async () => {
    await save()
    await analytics.track('business_selector_success', {
      businessType: values.businessType,
      locationType: values.locationType,
      locationTypeSpecification: values.locationTypeSpecification,
      locationSubtypes: values.locationSubtypes,
    })
  }, [values])

  useOnNextClick(saveAndTrack)

  const getPrimaryInitialValue = () => {
    if (values.businessType === 'other') {
      return {
        value: values.locationTypeSpecification,
        label: values.locationTypeSpecification,
      }
    }

    if (values.locationType) {
      const option = options.find((item) => item.value === values.locationType)
      return {
        value: values.locationType,
        label: option?.label,
      }
    }

    return undefined
  }

  const primaryInitialValues = getPrimaryInitialValue()
  const hasPrimarySelected =
    values.locationTypeSpecification || values.locationType

  const isButtonDisabled = useCallback(() => makeDisabled(values), [values])

  useNextDisabled(isButtonDisabled, [values])

  useSetButtonText(
    () =>
      isButtonDisabled()
        ? t('stepperButtonTextOptions.businessType')
        : t('next'),
    [values],
  )

  return (
    <>
      <div className="flex flex-col gap-2">
        {secondarySelections.length > 0 && (
          <Typography color={ThemeColor.b50} variant="p2Body">
            {t('mainActivity')}
          </Typography>
        )}
        <PrimaryActivityWrapper>
          {!isEmpty(errors) && (
            <Alert
              icon={InfoIcon}
              iconAlignment="top"
              text={t('businessTypeStepError')}
              variant="danger"
            />
          )}
          <FormAutoCompleteItem
            allowCreate
            initialValue={primaryInitialValues}
            isLoading={locationTypesLoading}
            name="businessType"
            omitOptions={[
              // FIXME: Remove this when we have a better BE-driven solution
              ...HIDDEN_OPTIONS,
              ...secondarySelections.map((item) => item.value),
            ]}
            onClear={handleClearPrimary}
            onValueChange={handleSelectPrimary}
            options={options}
            shouldRenderInModal={isMobile}
          />
        </PrimaryActivityWrapper>
      </div>

      {(hasPrimarySelected || secondarySelections.length > 0) && (
        <div className="flex flex-col gap-2">
          {secondarySelections.length > 0 && (
            <Typography color={ThemeColor.b50} variant="p2Body">
              {t('secondaryActivity')}
            </Typography>
          )}
          <MixedBusinessWrapper>
            {secondarySelections.length > 0 && (
              <SecondaryActivityWrapper>
                {secondarySelections.map((item) => (
                  <FormAutoCompleteItem
                    key={item.id}
                    initialValue={
                      values.locationSubtypes?.includes(item.value)
                        ? {
                            value: item.value,
                            label:
                              options.find(
                                (option) => option.value === item.value,
                              )?.label ?? item.value,
                          }
                        : null
                    }
                    name={`secondary-${item.id}}`}
                    omitOptions={[
                      values.locationType,
                      // FIXME: Remove this when we have a better BE-driven solution
                      ...HIDDEN_OPTIONS,
                      ...secondarySelections.map((i) => i.value),
                    ]}
                    onBlur={() => handleClearSecondary(item.id)}
                    onClear={() => handleClearSecondary(item.id)}
                    onValueChange={(option) =>
                      handleSelectSecondary(option, item.id)
                    }
                    options={options}
                    shouldFocusOnRender
                    shouldRenderInModal={isMobile}
                  />
                ))}
              </SecondaryActivityWrapper>
            )}

            <div>
              {hasPrimarySelected && secondarySelections.length < 3 && (
                <Button
                  onClick={handleAddSecondary}
                  size="sm"
                  suffixIcon={Plus}
                  variant="muted"
                >
                  {t('addOtherActivities')}
                </Button>
              )}
            </div>
          </MixedBusinessWrapper>
        </div>
      )}
    </>
  )
}
