import { Image, Typography } from '@/components/atoms'
import { ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled, { keyframes } from 'styled-components'
import { Card, Column, Row } from '../../styled'

export const Container = styled(Column)`
  align-items: center;
  display: flex;
  max-width: 974px;
  margin: 0 ${toThemePixels(2)};
  border-radius: ${toThemePixels(6)};
  padding: ${toThemePixels(6)};
  background-color: white;

  ${themeBreakpointDown('md')} {
    width: 100%;
  }

  > div > span {
    text-align: center;
  }
`

export const CardRow = styled(Row)`
  margin-top: ${toThemePixels(6)};
  gap: ${toThemePixels(6)};
  width: 100%;
  max-width: 64rem;

  ${themeBreakpointDown('md')} {
    flex-direction: column;
    align-items: center;
  }
`

const GradientCard = styled(Card)`
  position: relative;
  background: linear-gradient(180deg, #f5f6ff 0%, #e6e8ff 100%);
  border: 1px solid ${themeColor(ThemeColor.b30)};
  box-shadow: none;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  min-height: 410px;
  padding: 0;

  ${themeBreakpointDown('md')} {
    width: 100%;
  }
`

export const BigCard = styled(GradientCard)`
  flex: 6;
`

export const SmallCard = styled(GradientCard)`
  flex: 4;
`

export const CardTextTitle = styled(Column)`
  text-align: center;
  gap: ${toThemePixels(2)};
  padding: ${toThemePixels(6)};
`

export const RadialBg = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 4rem;
  width: 91.666667%;
  margin: 0 auto;
  height: 24rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/assets/circle-bg.svg');

  ${themeBreakpointDown('md')} {
    width: 100%;
    top: 6rem;
  }
`

export const StyledPhone = styled(Image)`
  position: absolute;
  right: 0;
  left: 0;
  z-index: 10;
  top: 40%;
  max-width: 270px;
  margin: 0 auto;

  ${themeBreakpointDown('md')} {
    top: 45%;
  }
`

export const StyledRiskIndicator = styled(Image)`
  max-width: 310px;
  margin: ${toThemePixels(12)} auto ${toThemePixels(8)} auto; ;
`

export const ClaimManagementTextContainer = styled.div`
  margin: ${toThemePixels(6)} 0;
  flex: 1;
  min-width: 0;
  overflow: hidden;
`

const blinkCaret = keyframes`
  from { border-color: transparent }
  50% { border-color: #000 }
`

const typing = keyframes`
  from { width: 0 }
  to { width: 100% }
`

export const ClaimManagementText = styled(Typography)`
  max-width: min-content;
  display: inline-block;
  overflow: hidden;
  position: relative;
  border-bottom-width: 2px;
  white-space: nowrap;
  user-select: none;
  overflow: hidden;
  border-right: 0.15em solid ${themeColor(ThemeColor.b100)};
  white-space: nowrap;
  margin: 0 auto;
  animation: ${typing} 3.5s steps(60, end), ${blinkCaret} 0.75s step-end;
`

export const ClaimManagementTextLine = styled.div`
  border-bottom: 2px solid black;
`

export const ClaimManagementCardsContainer = styled(Row)`
  flex-wrap: nowrap;
  pointer-events: none;
  user-select: none;

  ${themeBreakpointDown('md')} {
    & > div:nth-child(2) {
      display: none;
    }
  }
`

export const ClaimManagementCard = styled(Card)`
  width: 90%;
  margin-left: ${toThemePixels(3)};

  ${themeBreakpointDown('md')} {
    width: 95%;
    margin: 0 auto;
  }
`

export const StyledIntegrations = styled(Image)`
  height: 50%;
  ${themeBreakpointDown('sm')} {
    margin-bottom: ${toThemePixels(6)};
  }
`
