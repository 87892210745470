import { Breakpoint, ThemeColor } from '@/enums'
import { themeBreakpointDown, themeColor, toThemePixels } from '@/utils'
import styled, { keyframes } from 'styled-components'
import { ifProp, prop } from 'styled-tools'
import { Column, Row } from '../../styled'

const showModal = keyframes`
from {
  opacity: 0;
  transform:  translate(-50%, -50%) scale(0.3);
}

50% {
  opacity: 1;
}
`
const showModalMobile = keyframes`
from {
  opacity: 0;
  transform: translate(-50%,100%);
}

50% {
  opacity: 1;
}
`

export const Container = styled.div<{
  fullscreen?: boolean
  padding?: string
  noAnimation?: boolean
  absolute?: boolean
}>`
  background-color: ${themeColor(ThemeColor.b0)};
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: ${toThemePixels(84)};
  padding: ${ifProp(
    'fullscreen',
    '48px 0 0 0',
    ifProp('padding', prop('padding'), '48px 24px 0 24px'),
  )};
  transform: translate(-50%, -50%);
  border-radius: ${ifProp('fullscreen', '0', toThemePixels(4))};
  animation: ${ifProp('noAnimation', 'none', showModal)} 400ms;
  transform-origin: center;
  width: ${ifProp('fullscreen', '100vw')};
  height: ${ifProp('fullscreen', '100vh')};
  box-shadow: 0px 6px 12px rgba(11, 44, 93, 0.05),
    0px 0px 12px rgba(11, 44, 93, 0.05);

  > div {
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }

  ${themeBreakpointDown(Breakpoint.sm)} {
    animation: ${showModalMobile} 400ms;
    padding: ${ifProp('padding', prop('padding'), '24px')};
    width: 100vw;
    height: 100vh;
    height: 100svh;
    max-width: unset;
    transform: translate(-50%, 0%);
    top: unset;
    bottom: 0;
    min-width: unset;
    border-radius: 0;
    button {
      min-width: 100px;
    }
  }
`

export const ModalHeader = styled(Row)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-size: ${toThemePixels(6)};
  * {
    justify-content: center;
    align-items: center;
  }
`

export const ModalSubHeader = styled(Row)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-size: 16px;
  color: ${themeColor(ThemeColor.b50)};
`

export const CloseModalButton = styled.div`
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: ${toThemePixels(3)};
  right: ${toThemePixels(3)};
  z-index: 999;

  > svg {
    position: absolute;
  }

  &::after {
    content: '';
    display: block;
    width: ${toThemePixels(8)};
    height: ${toThemePixels(8)};
    background-color: rgba(234, 236, 237, 0.5);
    border-radius: ${toThemePixels(2)};
  }
`

export const ModalContent = styled.div<{
  withButtons?: string
  fullscreen?: boolean
}>`
  max-height: ${ifProp(
    'withButtons',
    'calc(100vh - 120px)',
    'calc(100vh - 74px)',
  )};
  overflow-y: auto;
`

export const ModalActions = styled(Column)`
  padding: ${toThemePixels(8)} 0;
  width: 100%;
  gap: ${toThemePixels(4)};
  justify-content: space-between;
  margin-right: ${toThemePixels(6)};
  ${themeBreakpointDown(Breakpoint.sm)} {
    gap: ${toThemePixels(2)};
    padding: ${toThemePixels(4)} 0;
    margin-right: 0;
  }
`

export const ModalDetails = styled(Column)`
  padding: ${ifProp('fullscreen', toThemePixels(6), '16px 0 0 0')};
  gap: ${toThemePixels(2)};
`

export const EmojiContainer = styled.div`
  font-size: ${toThemePixels(30)};
  width: min-content;
  height: min-content;
  margin-bottom: ${toThemePixels(6)};
`

export const HighlightsContainer = styled(Column)`
  gap: ${toThemePixels(2)};
`
